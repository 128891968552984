export default `
    mutation addNewLookup($input:LookupMutationAddLookupItemInput!) {
        lookups {
            addItems(input: $input) {
                clientMutationId
                errors {
                    message
                    path
                }
                hasErrors
                lookup {
                    name
                    items {
                        value
                        displayText
                        sortOrder
                        enabled
                        symbolName
                        filters
                        groupingId
                        isOperable
                        isPlanned
                        emailAddress
                    }
                }
            }
        }
    }
`