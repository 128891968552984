export default `
mutation editRiser_Mutation($input: EditRiserInput!) {
    waterSource {
        update {
            riser(input: $input) {
                clientMutationId
                waterSource {
                    ... on Riser {
                        waterSourceNodeId: id
                        waterSourceId: externalId
                        category {
                            value
                            displayText
                        }
                        classification {
                            value
                            displayText
                        }
                        isActive
                        status {
                            value
                            displayText
                        }
                        isDefective {
                            value
                            isOverridden
                        }
                        isOperable {
                            value
                            isOverridden
                        }
                        organisation {
                            organisationNodeId: id
                            organisationId
                            shortName
                            name
                            isActive
                        }
                        inspectionFrequency
                        lastInspectionDate
                        nextInspectionDate  
                        recommendedInspectionDate
                        additionalInfo         
                        location {
                            address {
                                addressNodeId: id
                                addressId
                                saon {
                                    description
                                }
                                paon {
                                    description
                                }
                                usrn
                                streetDescription
                                locality
                                town
                                postCode
                                administrativeArea
                                uprn
                                coordinates {
                                    x
                                    y
                                }
                            }
                            coordinates {
                                x
                                y
                            }
                            description
                        }
                        station {
                            displayText
                            value
                        }
                        route {
                            routeNodeId: id
                            routeId
                            name
                            isActive
                            station {
                                value
                                displayText
                            }
                        }
                        routeSortOrder
                        riskSeverity {
                            displayText
                            value                
                        }
                        hazardSeverity {
                            displayText
                            value
                        }
                        hazards {
                            hazardNodeId: id
                            hazardId
                            hazardType {
                                value
                                displayText
                            }
                        }
                        scheme {
                            schemeNodeId: id
                            schemeId
                            title
                        }
                        notes {
                            noteNodeId: id
                            noteId
                            timestamp
                            author
                            content
                        }
                        ... on ITagged {
                            tags {
                                value
                                displayText
                                enabled
                            }
                        }
                    }
                }
                hasErrors
                errors {
                    path
                    message
                }
            }
        }
    }
}`