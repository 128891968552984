import Feature from 'ol/Feature';

import { ISchemeAction, IScheme, IAddSchemeVariables, IChangeSchemeWaterSourceVariables, ISchemeStatusChange, ISchemeBoundary, IWaterPipe, IThoroughfare, ISchemeState } from '../types/schemes';
import executeQuery from '../../lib/executeQuery';
import { guid } from '../../lib/Utils';
import { IGlobalToast } from '../types/app';
import { setGlobalToast, setLoadingToast, setSuccessToast, clearGlobalToast, setInfoToast } from './app';
import { setError } from './errors';
import { navigateToLocationOnMap, setRenderPoints } from './map'
import getSchemeQuery from './graphQL/getScheme';
import createScheme from './graphQL/createScheme';
import updateScheme from './graphQL/updateScheme';
import removeWaterSourceFromScheme from './graphQL/removeWaterSourceFromScheme';
import addWaterSourceToScheme from './graphQL/addWaterSourceToScheme';
import acceptScheme from './graphQL/acceptScheme';
import archiveScheme from './graphQL/archiveScheme';
import cancelScheme from './graphQL/cancelScheme';
import reopenScheme from './graphQL/reopenScheme';
import setSchemeBoundary from './graphQL/setSchemeBoundary';
import createWaterPipeQuery from './graphQL/createWaterPipe';
import archiveWaterPipeQuery from './graphQL/archiveWaterPipe';
import addWaterPipesToSchemeQuery from './graphQL/addWaterPipesToScheme';
import removeWaterPipesFromSchemeQuery from './graphQL/removeWaterPipesFromScheme';
import createThoroughfareQuery from './graphQL/createThoroughfare';
import archiveThoroughfareQuery from './graphQL/archiveThoroughfare';
import addThoroughfaresToSchemeQuery from './graphQL/addThoroughfaresToScheme';
import removeThoroughfaresFromSchemeQuery from './graphQL/removeThoroughfaresFromScheme';
import { Coordinate } from 'ol/coordinate';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAddMutation, IPoint, IEditMutation } from '../types';
import { NodeID, encodeNodeId } from '../../lib/nodeIdentifier';

export const getScheme = (variables: { id: string }, setLoading = true) => {
    return async (dispatch) => {
        try {
            if (setLoading) {
                dispatch(setLoadingToast('Loading scheme data...'));
            }

            const data = await executeQuery<{ node: IScheme | undefined }>(getSchemeQuery, variables);
            dispatch(startSetSelectedScheme(data?.node));

            if (setLoading) {
                dispatch(setGlobalToast());
            }
        } catch (err) {
            dispatch(setError('Error getting scheme', err));
        }
    }
}

export const startSetSelectedScheme = (selectedScheme?: IScheme) => {
    return (dispatch: ThunkDispatch<any, any, AnyAction>): void => {
        if (!selectedScheme) {
            dispatch(clearGlobalToast());
            return;
        }

        // coordinates of the first water source in scheme
        const waterSourceCoordinates = selectedScheme.waterSources && selectedScheme.waterSources.length > 0
            ? [selectedScheme.waterSources[0].location.coordinates.x, selectedScheme.waterSources[0].location.coordinates.y]
            : undefined;
        // coordinates of first point of the scheme boundary
        const boundaryCoordinates = selectedScheme.boundary && selectedScheme.boundary.exteriorRing.coordinates.length > 0
            ? [selectedScheme.boundary.exteriorRing.coordinates[0].x, selectedScheme.boundary.exteriorRing.coordinates[0].y]
            : undefined;
        // prioritise navigation to water source over boundary
        const coordinates = waterSourceCoordinates
            ? waterSourceCoordinates
            : boundaryCoordinates
                ? boundaryCoordinates
                : undefined;
        if (coordinates) {
            dispatch(navigateToLocationOnMap(coordinates, false));
        }

        dispatch(setInfoToast(`Showing scheme ${selectedScheme.schemeId} - ${selectedScheme.title}`, 5000));
    };
};

export const startCreateScheme = (variables: IAddSchemeVariables) => {
    return async (dispatch: ThunkDispatch<ISchemeState, unknown, AnyAction>): Promise<IScheme | undefined> => {
        try {
            dispatch(setLoadingToast('Creating new scheme...'));

            const repsonse = await executeQuery<{ scheme: { create: { scheme: IScheme } } }>(createScheme, variables);

            const { scheme } = repsonse?.scheme.create ?? {};
            if (scheme) {
                dispatch(setCreateSchemeSuccess(true));
                dispatch(setSuccessToast(`Scheme ${scheme.schemeId} created`, 5000));
                dispatch(startSetSelectedScheme(scheme))
            }
            return scheme;
        } catch (err) {
            dispatch(setError('Error creating scheme', err))
        }
    }
}

export const startUpdateScheme = (variables: IAddSchemeVariables) => {
    return async (dispatch) => {
        try {
            const res = await executeQuery<{ scheme: { update: { scheme: IScheme } } }>(updateScheme, variables);
            const { scheme } = res?.scheme.update ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme ${scheme.schemeId} updated.`,
                    showToast: true,
                    timeout: 5000
                }

                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error updating scheme', err))

        }
    }
}

export const startRemoveWaterSourceFromScheme = (schemeNodeId: NodeID, waterSourceNodeId: NodeID) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        dispatch(setLoadingToast('Removing water source from scheme...'));
        try {
            const request: IChangeSchemeWaterSourceVariables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(schemeNodeId),
                    data: {
                        items: [encodeNodeId(waterSourceNodeId)]
                    }
                }
            };
            const response = await executeQuery<{ scheme: { removeWaterSources: { scheme: IScheme } } }>(removeWaterSourceFromScheme, request);
            const { scheme: update } = response?.scheme.removeWaterSources ?? {};
            if (update) {
                dispatch(setRenderPoints(true));
                dispatch(setSuccessToast(`Water source successfully removed from scheme ${update.schemeId}.`, 5000));
            }
        }
        catch (err) {
            dispatch(setError('Error removing water source from scheme', err));
        }
    };
};

export const startAddWaterSourceToScheme = (scheme: NodeID, waterSource: NodeID) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Adding water source to scheme...'));

            const request: IChangeSchemeWaterSourceVariables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: encodeNodeId(scheme),
                    data: {
                        items: [encodeNodeId(waterSource)]
                    }
                }
            };
            const response = await executeQuery<{ scheme: { addWaterSources: { scheme: IScheme } } }>(addWaterSourceToScheme, request);
            const { scheme: updated } = response?.scheme.addWaterSources ?? {};
            if (updated) {
                dispatch(setRenderPoints(true));
                dispatch(setSuccessToast(`Water source successfully added to scheme ${updated.schemeId}.`, 5000));
            }
        }
        catch (err) {
            dispatch(setError('Error adding water source to scheme', err));
        }
    };
};

export const startAcceptScheme = (variables: ISchemeStatusChange) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingToast('Accepting Scheme...'));
            const res = await executeQuery<{ scheme: { accept: { scheme: IScheme } } }>(acceptScheme, variables)
            const { scheme } = res?.scheme.accept ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme ${scheme.schemeId} accepted.`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error accepting scheme.', err));

        }
    }
}

export const startArchiveScheme = (variables: ISchemeStatusChange) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingToast('Archiving scheme...'));
            const res = await executeQuery<{ scheme: { archive: { scheme: IScheme } } }>(archiveScheme, variables)
            const { scheme } = res?.scheme.archive ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme ${scheme.schemeId} archived.`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error archiving scheme.', err));

        }
    }
}

export const startCancelScheme = (variables: ISchemeStatusChange) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingToast('Cancelling Scheme...'));
            const res = await executeQuery<{ scheme: { cancel: { scheme: IScheme } } }>(cancelScheme, variables)
            const { scheme } = res?.scheme.cancel ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme ${scheme.schemeId} cancelled.`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error cancelling scheme.', err));

        }
    }
}

export const startReopenScheme = (variables: ISchemeStatusChange) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingToast('Re-opening scheme...'));
            const res = await executeQuery<{ scheme: { reopen: { scheme: IScheme } } }>(reopenScheme, variables)
            const { scheme } = res?.scheme.reopen ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme ${scheme.schemeId} re-opened.`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error re-opening scheme.', err));

        }
    }
}

export const startSetSchemeBoundary = (boundary: ISchemeBoundary) => {
    return async (dispatch) => {
        try {
            dispatch(setLoadingToast('Setting scheme boundary...'));
            const res = await executeQuery<{ scheme: { setBoundary: { scheme: IScheme } } }>(setSchemeBoundary, boundary)
            const { scheme } = res?.scheme.setBoundary ?? {};
            if (scheme) {
                const globalToast: IGlobalToast = {
                    type: 'SUCCESS',
                    message: `Scheme boundary set for scheme ${scheme.schemeId}.`,
                    showToast: true,
                    timeout: 5000
                }
                dispatch(setGlobalToast(globalToast));
                dispatch(startSetSelectedScheme(scheme))
            }
        } catch (err) {
            dispatch(setError('Error setting scheme boundary.', err));

        }
    }
}

export const createWaterPipe = (schemeNodeId: string, path: IPoint[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<IWaterPipe | undefined> => {
        try {
            dispatch(setLoadingToast('Creating water pipe...'));

            const createPipeRequest: IAddMutation<{ readonly coordinates: IPoint[]; }> = {
                input: {
                    clientMutationId: guid(),
                    data: {
                        coordinates: path
                    }
                }
            };
            const response = await executeQuery<{ gis: { waterPipe: { create: { waterPipe: IWaterPipe } } } }>(createWaterPipeQuery, createPipeRequest);
            const { waterPipe } = response?.gis.waterPipe.create ?? {};
            if (waterPipe) {
                const addPipeRequest: IEditMutation<{ readonly items: string[]; }> = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: schemeNodeId,
                        data: {
                            items: [waterPipe.waterPipeNodeId]
                        }
                    }
                };
                await executeQuery<{ scheme: { addWaterPipes: { scheme: IScheme } } }>(addWaterPipesToSchemeQuery, addPipeRequest);

                dispatch(setSuccessToast(`Water pipe ${waterPipe.waterPipeId} added to scheme`, 5000));
            }
            return waterPipe;
        }
        catch (err) {
            dispatch(setError('Error creating water pipe', err));
            return undefined;
        }
    }
}

export const removeWaterPipe = (selectedScheme: IScheme, clickedFeature: Feature) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Removing water pipe from scheme...'));
            const pipeNodeId = clickedFeature.get("nodeId") as string;
            const archiveVariables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: pipeNodeId
                }
            };
            const response = await executeQuery<{ gis: { waterPipe: { archive: { waterPipe: IWaterPipe } } } }>(archiveWaterPipeQuery, archiveVariables);

            const { waterPipe } = response?.gis.waterPipe.archive ?? {};
            if (waterPipe) {
                const removeVariables = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: selectedScheme.schemeNodeId,
                        data: {
                            items: pipeNodeId
                        }
                    }
                };
                await executeQuery<{ scheme: { removeWaterPipes: { scheme: IScheme } } }>(removeWaterPipesFromSchemeQuery, removeVariables);

                dispatch(setSuccessToast(`Water Pipe ${waterPipe.waterPipeId} removed from scheme`, 5000));
            }
        }
        catch (err) {
            dispatch(setError('Error removing water pipe', err));
        }
    };
};

export const createThoroughfare = (schemeNodeId: string, path: IPoint[]) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<IThoroughfare | undefined> => {
        try {
            dispatch(setLoadingToast('Creating thoroughfare...'));

            const createThoroughfareRequest: IAddMutation<{ path: { readonly coordinates: IPoint[]; }; }> = {
                input: {
                    clientMutationId: guid(),
                    data: {
                        path: {
                            coordinates: path
                        }
                    }
                }
            };
            const response = await executeQuery<{ gis: { thoroughfare: { create: { thoroughfare: IThoroughfare } } } }>(createThoroughfareQuery, createThoroughfareRequest);
            const { thoroughfare } = response?.gis.thoroughfare.create ?? {};
            if (thoroughfare) {
                const addThoroughfareRequest = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: schemeNodeId,
                        data: {
                            items: [thoroughfare.thoroughfareNodeId]
                        }
                    }
                }
                await executeQuery<{ scheme: { addThoroughfares: { scheme: IScheme } } }>(addThoroughfaresToSchemeQuery, addThoroughfareRequest);

                dispatch(setSuccessToast(`Thoroughfare ${thoroughfare.thoroughfareId} added to scheme`, 5000));
            }
            return thoroughfare;
        }
        catch (err) {
            dispatch(setError('Error creating thoroughfare', err));
            return undefined;
        }
    }
}

export const removeThoroughfare = (selectedScheme: IScheme, clickedFeature: Feature) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Removing thoroughfare from scheme...'));

            const thoroughfareNodeId = clickedFeature.get("nodeId") as string;
            const archiveVariables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: thoroughfareNodeId
                }
            };
            const response = await executeQuery<{ gis: { thoroughfare: { archive: { thoroughfare: IThoroughfare } } } }>(archiveThoroughfareQuery, archiveVariables);

            const { thoroughfare } = response?.gis.thoroughfare.archive ?? {};
            if (thoroughfare) {
                const removeVariables = {
                    input: {
                        clientMutationId: guid(),
                        nodeId: selectedScheme.schemeNodeId,
                        data: {
                            items: thoroughfareNodeId
                        }
                    }
                };
                await executeQuery<{ scheme: { removeThoroughfares: { scheme: IScheme } } }>(removeThoroughfaresFromSchemeQuery, removeVariables);

                dispatch(setSuccessToast(`Thoroughfare ${thoroughfare.thoroughfareId} removed from scheme`, 5000));
            }
        }
        catch (err) {
            dispatch(setError('Error removing thoroughfare', err));
        }
    };
};

export const setEditBoundary = (boundary: Coordinate[]): ISchemeAction => {
    return {
        type: 'SET_EDIT_BOUNDARY',
        boundary
    }
}

export const setCreateSchemeSuccess = (createSchemeSuccess?: boolean): ISchemeAction => {
    return {
        type: 'SET_CREATE_SCHEME_SUCCESS',
        createSchemeSuccess
    }
}

export const addWaterPipeToSelectedScheme = (waterPipes: object[]): ISchemeAction => {
    return {
        type: 'ADD_SELECTED_SCHEME_WATER_PIPE',
        waterPipes
    }
}

export const removeWaterPipeFromSelectedScheme = (waterPipes: object[]): ISchemeAction => {
    return {
        type: 'REMOVE_SELECTED_SCHEME_WATER_PIPE',
        waterPipes
    }
}

export const addThoroughfareToSelectedScheme = (thoroughfares: object[]): ISchemeAction => {
    return {
        type: 'ADD_SELECTED_SCHEME_THOROUGHFARE',
        thoroughfares
    }
}

export const removeThoroughfareFromSelectedScheme = (thoroughfares: object[]): ISchemeAction => {
    return {
        type: 'REMOVE_SELECTED_SCHEME_THOROUGHFARE',
        thoroughfares
    }
}