export default `
fragment WaterSource on IWaterSource {
    waterSourceNodeId: id
    waterSourceId
    location {
        description
        coordinates {
            x
            y
        }
        address {
            streetDescription
            locality
            town
        }
    }
    ... on Hydrant {
        hydrantLocation {
            displayText
            value
        }
    }
}
fragment Defect on Defect {
    defectNodeId: id
    defectId
    type {
        displayText
        value
    }
}
fragment Inspection on Inspection {
    inspectionNodeId: id
    inspectionId
    type {
        displayText
        value
    }
}
query getRepair_Query($id: ID!) {
    node(id:$id) {
        ... on Repair {
            repairNodeId: id          
            repairId
            waterSource {
                ...WaterSource
            }
            defect {
                ...Defect
            }
            inspection {
                ...Inspection
            }
            repairCategory {
                displayText
                value
            }
            repairType {
                displayText
                value
            }
            repairVendor {
                displayText
                value
                emailAddress
            }
            expectedCost
            actualCost
            costSaving
            isPaymentAuthorised
            workOrderRef
            purchaseOrderRef
            invoiceRef
            dateWorkCompleted
            dateInvoiced
            dateCreated
            datePaid
            dateGuaranteeExpires
            cancelReason {
                displayText
                value
            }
            isOpen
            isWorkCompleted
            isInvoiced
            isPaymentAuthorised
            isPaid
            isCancelled
            lastEvent {
                action
                actor
                timestamp
            }
            events {
                action
                actor
                timestamp
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
        }
    }
}`;