export default `
fragment Item on LookupItem {
    value
    displayText
    sortOrder
    enabled
    symbolName
    filters
    groupingId
    isOperable
    isPlanned
    emailAddress
}
query AppWindow_ConfigurableLookupsQuery {
    lookups {
        defectTypes {
            ...Item
        }
        hazardTypes {
            ...Item
        }
        hydrantLocations {
            ...Item
        }
        inspectionTypes {
            ...Item
        }
        organisations {
            ...Item
        }
        repairTypes {
            ...Item
        }
        repairVendors {
            ...Item
        }
        surfaces {
            ...Item
        }
        tags {
            ...Item
        }
    }
}`;