import { getMax, getMin } from "../../lib/Utils";
import { ILookupItem, ILookups } from "../../store/types"
import { IEditedLookup } from "./ConfigurationPage.view";

export type GraphQlLookupType = 'CONTACT_TYPE' | 'CONTROL_MEASURE' | 'CREW' | 'DEFECT_CANCEL_REASON' | 'DEFECT_CLOSE_REASON' | 'DEFECT_TYPE' | 'HAZARD_FLAG' | 'HAZARD_TYPE' | 'HYDRANT_LOCATION' | 'INSPECTION_CANCEL_REASON' | 'INSPECTION_GROUP' | 'INSPECTION_RESULT' | 'INSPECTION_TEST' | 'INSPECTION_TYPE' | 'MAINS_SIZE_UNIT' | 'MEASUREMENT_UNIT' | 'MEASUREMENT_UNIT_CATEGORY' | 'MEASUREMENT_UNIT_SYSTEM' | 'ORGANISATION' | 'ORGANISATION_TYPE' | 'PLATE_LOCATION' | 'PUMP_TYPE' | 'REPAIR_CANCEL_REASON' | 'REPAIR_TYPE' | 'REPAIR_VENDOR' | 'RISK_SEVERITY' | 'ROAD_SPEED' | 'ROUTE' | 'SCHEME_STAGE' | 'SCHEME_TYPE' | 'STATION' | 'SURFACE' | 'TAG' | 'TECHNICIAN' | 'WATER_SOURCE_CLASSIFICATION' | 'WATER_SOURCE_STATUS'

const arrayContainsDuplicates = (arr: number[]): boolean => {
    return arr.length !== new Set(arr).size;
}

export const moveLookup = (lookups: ILookupItem[], lookupValueToMove: number, direction: 'UP' | 'DOWN'): ILookupItem[] => {
    const filteredLookups = lookups.filter(lookup => lookup.enabled);
    const sortOrders = filteredLookups.map(lookup => lookup.sortOrder);
    let updatedLookups = lookups;

    if (arrayContainsDuplicates(sortOrders)) {
        updatedLookups = filteredLookups.map((lookup, index) => ({
            ...lookup,
            sortOrder:  index + 1
        }));
    }

    const lookupToMove = updatedLookups.find(lookup => lookup.value === lookupValueToMove);
    if (lookupToMove) {
        const minLookupSortNumber = updatedLookups.map(lookup => lookup.sortOrder).reduce(getMin, 1)
        const maxLookupSortNumber = updatedLookups.filter(lookup => lookup.enabled).map(lookup => lookup.sortOrder).reduce(getMax, 1)
        if ((direction === 'UP' && lookupToMove.sortOrder === minLookupSortNumber) || (direction === 'DOWN' && lookupToMove.sortOrder === maxLookupSortNumber)) {
            return lookups;
        }

        const newSortOrder = direction === 'UP' ? lookupToMove.sortOrder - 1 : lookupToMove.sortOrder + 1;

        if (direction === 'UP') {
            return updatedLookups.map(lookup => {
                if (lookup.value === lookupValueToMove) {
                    return {
                        ...lookup,
                        sortOrder: newSortOrder
                    }
                }

                if (lookup.enabled && lookup.sortOrder === newSortOrder) {
                    return {
                        ...lookup,
                        sortOrder: lookup.sortOrder + 1
                    }
                }

                return lookup;
            }).sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1);
        }

        if (direction === 'DOWN') {
            return updatedLookups.map(lookup => {
                if (lookup.value === lookupValueToMove) {
                    return {
                        ...lookup,
                        sortOrder: newSortOrder
                    }
                }

                if (lookup.enabled && lookup.sortOrder === newSortOrder) {
                    return {
                        ...lookup,
                        sortOrder: lookup.sortOrder - 1
                    }
                }

                return lookup;
            }).sort((a, b) => a.sortOrder >= b.sortOrder ? 1 : -1);
        }
    }
    return updatedLookups;
}

export const findNewlyAddedLookup = (originalLookups: ILookupItem[], newLookups: ILookupItem[]): ILookupItem | undefined => {
    let newLookup: ILookupItem | undefined = undefined;
    newLookups.forEach(lookup => {
        const foundLookup = originalLookups.find(originalLookup => originalLookup.value === lookup.value);
        if (!foundLookup) {
            newLookup = lookup;
        }
    });

    return newLookup;
}

export const findAndFormatChangedLookups = (originalLookups: ILookupItem[], newLookups: ILookupItem[]): IEditedLookup[] => {
    const updatedLookups: IEditedLookup[] = [];
    originalLookups.forEach(originalLookup => {
        const newLookup = newLookups.find(newLookup => originalLookup.value === newLookup.value);
        if (newLookup) {
            const originalLookupJSON = JSON.stringify(originalLookup);
            const newLookupJSON = JSON.stringify(newLookup);

            if (newLookupJSON !== originalLookupJSON) {
                updatedLookups.push({
                    value: newLookup.value,
                    displayText: newLookup.displayText,
                    sortOrder: newLookup.sortOrder,
                    filters: newLookup.filters,
                    isOperable: newLookup.isOperable,
                    isPlanned: newLookup.isPlanned,
                    groupingId: newLookup.groupingId,
                    symbolName: newLookup.symbolName,
                    emailAddress: newLookup.emailAddress
                });
            }
        }
    });
    return updatedLookups;
}

export const convertLookupKeyToLookupType = (lookupKey: keyof ILookups): GraphQlLookupType | undefined => {
    switch (lookupKey) {
        case 'contactTypes':
            return 'CONTACT_TYPE';
        case 'controlMeasures':
            return 'CONTROL_MEASURE';
        case 'crews':
            return 'CREW';
        case 'defectCancelReasons':
            return 'DEFECT_CANCEL_REASON';
        case 'defectCloseReasons':
            return 'DEFECT_CLOSE_REASON';
        case 'defectTypes':
            return 'DEFECT_TYPE';
        case 'hazardFlags':
            return 'HAZARD_FLAG';
        case 'hazardTypes':
            return 'HAZARD_TYPE';
        case 'hydrantLocations':
            return 'HYDRANT_LOCATION';
        case 'inspectionCancelReasons':
            return 'INSPECTION_CANCEL_REASON';
        case 'inspectionGroups':
            return 'INSPECTION_GROUP';
        case 'inspectionResults':
            return 'INSPECTION_RESULT';
        case 'inspectionTests':
            return 'INSPECTION_TEST';
        case 'inspectionTypes':
            return 'INSPECTION_TYPE';
        case 'mainsSizeUnits':
            return 'MAINS_SIZE_UNIT';
        case 'measurementUnitCategories':
            return 'MEASUREMENT_UNIT_CATEGORY';
        case 'measurementUnitSystems':
            return 'MEASUREMENT_UNIT_SYSTEM';
        case 'measurementUnits':
            return 'MEASUREMENT_UNIT';
        case 'organisationTypes':
            return 'ORGANISATION_TYPE';
        case 'organisations':
            return 'ORGANISATION';
        case 'plateLocations':
            return 'PLATE_LOCATION';
        case 'pumpTypes':
            return 'PUMP_TYPE';
        case 'repairCancelReasons':
            return 'REPAIR_CANCEL_REASON';
        // case 'repairCategories':
        //     return 'rep'
        case 'repairTypes':
            return 'REPAIR_TYPE';
        case 'repairVendors':
            return 'REPAIR_VENDOR';
        case 'riskSeverity':
            return 'RISK_SEVERITY';
        case 'roadSpeed':
            return 'ROAD_SPEED';
        case 'schemeStages':
            return 'SCHEME_STAGE';
        case 'schemeTypes':
            return 'SCHEME_TYPE';
        case 'stations':
            return 'STATION';
        case 'surfaces':
            return 'SURFACE';
        case 'tags':
            return 'TAG';
        case 'technicians':
            return 'TECHNICIAN';
        // case 'waterSourceCategories':
        //     return 'water'
        case 'waterSourceClasses':
            return 'WATER_SOURCE_CLASSIFICATION'
        case 'waterSourceStatuses':
            return 'WATER_SOURCE_STATUS'
        default:
            return undefined;
    }
}

export const convertLookupTypeToLookupKey = (lookupType: GraphQlLookupType): keyof ILookups | undefined => {
    switch (lookupType) {
        case 'CONTACT_TYPE':
            return 'contactTypes';
        case 'CONTROL_MEASURE':
            return 'contactTypes';
        case 'CREW':
            return 'crews';
        case 'DEFECT_CANCEL_REASON':
            return 'defectCancelReasons'
        case 'DEFECT_CLOSE_REASON':
            return 'defectCloseReasons'
        case 'DEFECT_TYPE':
            return 'defectTypes'
        case 'HAZARD_FLAG':
            return 'hazardFlags';
        case 'HAZARD_TYPE':
            return 'hazardTypes';
        case 'HYDRANT_LOCATION':
            return 'hydrantLocations';
        case 'INSPECTION_CANCEL_REASON':
            return 'inspectionCancelReasons';
        case 'INSPECTION_GROUP':
            return 'inspectionGroups';
        case 'INSPECTION_RESULT':
            return 'inspectionResults';
        case 'INSPECTION_TEST':
            return 'inspectionTests';
        case 'INSPECTION_TYPE':
            return 'inspectionTypes';
        case 'MAINS_SIZE_UNIT':
            return 'mainsSizeUnits';
        case 'MEASUREMENT_UNIT':
            return 'measurementUnits';
        case 'MEASUREMENT_UNIT_CATEGORY':
            return 'measurementUnitCategories';
        case 'MEASUREMENT_UNIT_SYSTEM':
            return 'measurementUnitSystems';
        case 'ORGANISATION':
            return 'organisations';
        case 'ORGANISATION_TYPE':
            return 'organisationTypes';
        case 'PLATE_LOCATION':
            return 'plateLocations';
        case 'PUMP_TYPE':
            return 'pumpTypes';
        case 'REPAIR_CANCEL_REASON':
            return 'repairCancelReasons';
        case 'REPAIR_TYPE':
            return 'repairTypes';
        case 'REPAIR_VENDOR':
            return 'repairVendors';
        case 'RISK_SEVERITY':
            return 'riskSeverity';
        case 'ROAD_SPEED':
            return 'roadSpeed';
        case 'ROUTE':
            return 'routes';
        case 'SCHEME_STAGE':
            return 'schemeStages';
        case 'STATION':
            return 'stations';
        case 'SURFACE':
            return 'surfaces';
        case 'TAG':
            return 'tags';
        case 'TECHNICIAN':
            return 'technicians';
        case 'WATER_SOURCE_CLASSIFICATION':
            return 'waterSourceClasses';
        case 'WATER_SOURCE_STATUS':
            return 'waterSourceStatuses';
        default:
            return undefined;
    }
}