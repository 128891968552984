import React from "react";
import classnames from "classnames";

import { EditElementProps } from "../../types";
import { containerClass, inputClass, labelClass } from "../../labelledField.utils";

import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import ReadOnlyElement from "../readOnlyElement";

interface NumberElementProps extends EditElementProps<number> {
    readonly minValue: number | undefined;
}

const NumberElement = (props: NumberElementProps): JSX.Element => {
    if (props.editing) {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            const value = e.target.value === ""
                ? undefined
                : Number(e.target.value);
            props.onChange(value);
        };
        return (
            <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
                <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />
                <input
                    type="number"
                    placeholder={props.label}
                    id={props.id}
                    name={props.id}
                    className={classnames("input", inputClass(props))}
                    value={props.value}
                    min={props.minValue}
                    onChange={handleChange}
                />
            </ElementContainer>
        );
    }
    return <ReadOnlyElement id={props.id} label={props.label} noMargin={props.noMargin} classes={props.classes} value={props.value} />;
};

export type { NumberElementProps };
export default NumberElement;