import React from "react";

import { IRoute, IScheme, isScheme, isRoute } from "../../../store/types";
import { RoutesPanel } from "../../routes";
import SchemePanel from "../../schemes/schemePanel";
import Drawer from "../Drawer";

interface LeftDrawerProps {
    readonly open: boolean;
    readonly entity: IRoute | IScheme | undefined;
    readonly onClose?: (entity: "route" | "scheme") => void;
    readonly onRefresh?: (entity: "route" | "scheme") => void;
}

export const LeftDrawer = (props: LeftDrawerProps): JSX.Element => {
    const { open, entity, onClose, onRefresh } = props;

    const renderPanel = (): JSX.Element => {
        const handleClose = (entity: "route" | "scheme") => {
            return (): void => {
                onClose?.(entity);
            };
        };
        const handleRefresh = (entity: "route" | "scheme") => {
            return (): void => {
                onRefresh?.(entity);
            }
        }
        if (isScheme(entity)) {
            return <SchemePanel scheme={entity} onClose={handleClose("scheme")} onUpdateScheme={handleRefresh("scheme")} />;
        }
        if (isRoute(entity)) {
            return <RoutesPanel route={entity} onClose={handleClose("route")} onUpdateRoute={handleRefresh("route")} />;
        }
        return <React.Fragment />;
    };

    return (
        <Drawer isOpen={open} anchor="left">
            {renderPanel()}
        </Drawer>
    );
};

export type { LeftDrawerProps };
export default LeftDrawer;
