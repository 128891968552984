import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from '../../../../../lib/hooks/useSelector';
import { guid } from '../../../../../lib/Utils';
import { setGlobalToast, setGlobalNotice } from '../../../../../store/actions/app';
import { startSetMapClickAction } from '../../../../../store/actions/map';
import {
    startUpdateScheme,
    startSetSchemeBoundary
} from '../../../../../store/actions/schemes';
import { AddressDetailsData } from "../../../../address";
import { IPolygon, IAddSchemeVariables, ISchemeBoundary, IGlobalNotice } from '../../../../../store/types';
import { ISchemeDetailsData } from './components/schemeDetails';
import { SchemeWorkflowData } from './components/schemeWorkflow';
import SchemeDetailsTabBase, { SchemeDetailsTabProps } from './schemeDetailsTab';

const SchemeDetailsTab = ({ scheme, editing, onEditing, onUpdateScheme, hasBoundary }: Pick<SchemeDetailsTabProps, "scheme" | "editing" | "onEditing" | "onUpdateScheme" | "hasBoundary">): JSX.Element => {
    const dispatch = useDispatch();

    const schemeStages = useSelector(state => state.app.lookups?.schemeStages ?? []);
    const schemeTypes = useSelector(state => state.app.lookups?.schemeTypes ?? []);
    const stations = useSelector(state => state.app.lookups?.stations ?? []);
    const mapClickAction = useSelector(state => state.map.mapClickAction);

    const props: SchemeDetailsTabProps = {
        scheme,
        schemeStages,
        schemeTypes,
        stations,
        mapClickAction,
        editing,
        hasBoundary,
        onEditing,
        onError: (message): void => {
            dispatch(setGlobalToast({
                type: 'ERROR',
                message: message,
                showToast: true
            }));
        },
        onClearMapAction: (): void => {
            dispatch(startSetMapClickAction());
        },
        onUpdateBoundary: async (schemeNodeId: string, boundary: IPolygon | undefined): Promise<void> => {
            const input: ISchemeBoundary = {
                input: {
                    clientMutationId: guid(),
                    nodeId: schemeNodeId,
                    data: boundary
                        ? {
                            exteriorRing: {
                                ...boundary.exteriorRing,
                            },
                            interiorRings: []
                        }
                        : { exteriorRing: { coordinates: [] }, interiorRings: [] }
                }
            };
            const action = startSetSchemeBoundary(input);
            await action(dispatch);
        },
        onUpdateScheme: async (schemeNodeId: string, details: ISchemeDetailsData | undefined, address: AddressDetailsData | undefined, workflow: SchemeWorkflowData | undefined): Promise<void> => {
            const variables: IAddSchemeVariables = {
                input: {
                    clientMutationId: guid(),
                    nodeId: schemeNodeId,
                    data: {
                        typeId: details?.type?.value ?? null,
                        title: details?.title,
                        reference: details?.reference ?? "",
                        description: details?.description ?? null,
                        stationId: details?.station?.value ?? null,
                        addressId: address?.addressId ?? null,
                        stageId: workflow?.stage?.value ?? null,
                        dateReceived: workflow?.dateReceived ?? null,
                        dateReturned: workflow?.dateReturned ?? null,
                        dateOrdered: workflow?.dateOrdered ?? null,
                        orderReference: workflow?.orderReference ?? null,
                        invoiceDueDate: workflow?.invoiceDueDate ?? null,
                        invoiceReference: workflow?.invoiceReference ?? null,
                        cost: workflow?.cost ?? null,
                        dateClosedExternally: workflow?.dateClosedExternally ?? null,
                        dateClosed: workflow?.dateClosed ?? null
                    }
                }
            };
            const action = startUpdateScheme(variables);
            await action(dispatch);
            onUpdateScheme?.(schemeNodeId, details, address, workflow);
        },
        setGlobalNotice: (globalNotice?: IGlobalNotice): void => {
            dispatch(setGlobalNotice(globalNotice));
        }
    };
    return <SchemeDetailsTabBase {...props} />;
}

export default SchemeDetailsTab;
