import classnames from 'classnames';
import React from 'react';

import { containerClass, inputClass, labelClass } from '../../labelledField.utils';
import { ElementProps } from '../../types';
import ElementContainer from '../elementContainer';
import LabelElement from '../labelElement';
import ReadOnlyElement from '../readOnlyElement';
import { SelectOption } from '../selectElement/types';
import SelectOptions from '../selectElement/components/SelectOptions';

import styles from "./multiSelectElement.module.scss";

interface MultiSelectElementProps extends ElementProps {
    readonly options: SelectOption[];
    readonly selectedOptions: SelectOption[];
    readonly noOptionsMessage?: string;
    readonly editing: boolean;
    readonly onChange: (value: SelectOption[]) => void;
    readonly onClear?: () => void;
}

const defaultLabelPosition = "TOP";
const defaultNoOptionsMessage = "No items";

const MultiSelect = (props: MultiSelectElementProps): JSX.Element => {
    const {
        id,
        options,
        selectedOptions,
        editing,
        label,
        noMargin,
        classes,
        noOptionsMessage = defaultNoOptionsMessage,
        labelPosition = defaultLabelPosition,
        onChange,
        onClear
    } = props;

    if (editing) {
        const canShowInput = Boolean(options.length);
        const canShowClear = Boolean(onClear) && canShowInput;
        const canShowFooter = labelPosition === "BOTTOM" || canShowClear;

        const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
            const selectedValues = Array.from(event.target.selectedOptions).map(selectedOption => selectedOption.value);
            const newSelectedOptions: SelectOption[] = [];

            selectedValues.forEach(selectedValue => {
                options.forEach(option => {
                    if (String(option.value) === String(selectedValue)) {
                        newSelectedOptions.push(option);
                    }
                })
            });

            onChange(newSelectedOptions);
        };

        const handleClear = (): void => {
            onClear?.();
        }

        return (
            <ElementContainer noMargin={noMargin} classes={containerClass(props)}>
                {labelPosition === "TOP" && <LabelElement ownerId={id} label={label} classes={labelClass(props)} />}
                <div className={styles.inputGroup}>
                    {canShowInput
                        ? (
                            <select
                                multiple
                                className={classnames("input", inputClass(props))}
                                id={id}
                                name={id}
                                value={selectedOptions.map(selectedOption => String(selectedOption.value))}
                                onChange={handleChange}
                            >
                                <SelectOptions selectOptions={options} />
                            </select>
                        )
                        : <span className={styles.noOptionsMessage}>{noOptionsMessage}</span>
                    }
                    {canShowFooter && (
                        <div className={styles.footer}>
                            {labelPosition === "BOTTOM" && <LabelElement ownerId={id} label={label} classes={labelClass(props)} />}
                            {canShowClear && <span className={styles.clearButton} onClick={handleClear}>clear</span>}
                        </div>
                    )}
                </div>
            </ElementContainer>
        )
    }

    const readOnlyValue = selectedOptions.length
        ? selectedOptions.map(selectedOption => selectedOption.displayText).join(", ")
        : "-";

    return <ReadOnlyElement id={id} label={label} noMargin={noMargin} classes={classes} value={readOnlyValue} />
}

export type { MultiSelectElementProps };
export default MultiSelect;