import React, { Component } from 'react';
import Feature from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import { SelectEvent } from 'ol/interaction/Select';
import { Coordinate } from 'ol/coordinate';
import MapBrowserPointerEvent from 'ol/MapBrowserPointerEvent';
import * as proj from "ol/proj";

import { renderWaterSources, waterSourceStyleInfo, pointStyle, ClusterStyleInfo, WaterSourceFeatureData } from '../../lib/map/Map';
import { NodeID, parseNodeId } from '../../lib/nodeIdentifier';
import { ILookupItem } from '../../store/types/app';
import { ICoordinate, IPoint } from '../../store/types/geometry';
import { IMapSettings, MapClickAction, IStreetViewParams } from '../../store/types/map';
import { IRoute } from '../../store/types/routes';
import { IScheme, IWaterPipe, IThoroughfare } from '../../store/types/schemes';
import { IWaterSource, IMapWaterSource } from '../../store/types/waterSources';
import { IWaterSourceSelectedFilters } from '../filters/waterSourceSearch/types';
import MapKey from "../mapKey/MapKey";
import Map from '../map/desktop';
import { MapControlButton, PolygonInfo, MapDrawGeometry, LineNodeFeature } from '../map/desktop/Map';
import MapWarning from '../map/MapWarning';
import StreetView from '../streetView';
import './MapPage.scss';

interface IMapPageProps {
    readonly mapSettings: IMapSettings;
    readonly mapFallbackWarning: boolean;
    readonly statuses: ILookupItem[];
    readonly waterSources: IMapWaterSource[];
    readonly filters: IWaterSourceSelectedFilters;
    readonly accessControl?: {
        readonly manageWaterSources?: boolean;
    }
    readonly mapClickAction?: MapClickAction;
    readonly movingWaterSource?: boolean;
    readonly renderAnimateTo?: number[];
    readonly renderPoints?: boolean;
    readonly selectedRoute?: IRoute;
    readonly selectedScheme?: IScheme;
    readonly selectedWaterSource?: IWaterSource;
    readonly selectWaterSourceNodeId?: string;
    readonly shouldReloadWaterSources?: boolean;
    readonly editingSelectedWaterSource: boolean;

    readonly createThoroughfare: (schemeNodeId: string, path: IPoint[]) => void;
    readonly createWaterPipe: (schemeNodeId: string, path: IPoint[]) => void;
    readonly getWaterSources: (variables: Record<string, unknown>) => void;
    readonly getWaterSourcesByFilter: (variables: IWaterSourceSelectedFilters, pageIndex: number, forMap: boolean) => void;
    readonly onChangeSchemeBoundary?: (schemeNodeId: string, boundary: ICoordinate[]) => void;
    readonly onCreateWaterSource?: () => void;
    readonly onClearWaterSource?: () => void;
    readonly onMapClearFiltersClick?: () => void;
    readonly onMapCreateWaterSourceClick?: () => void;
    readonly onMapMeasureDistanceClick?: () => void;
    readonly onMapOpenStreetViewClick?: () => void;
    readonly onMapSelectClick?: () => void;
    readonly onMeasuredDistance?: (length: string) => void;
    readonly onSelectWaterSource?: (id: NodeID) => void;
    readonly onTileServerFallback?: (showWarning: boolean) => void;
    readonly removeWaterPipe: (scheme: IScheme, feature: Feature) => void;
    readonly removeThoroughfare: (scheme: IScheme, feature: Feature) => void;
    readonly setCreateFromMapLocation: (createFromMapLocation: [number, number]) => void;
    readonly setRenderPoints: (renderPoints: boolean) => void;
    readonly setMapCenterCoords: (mapCoords: Coordinate | undefined) => void;
    readonly setMovingWaterSource: (movingWaterSource?: boolean, movingWaterSourceLocation?: [number, number]) => void;
    readonly setIsEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean) => void;
}

interface IMapPageState {
    readonly drawOnMap: false | MapDrawGeometry;
    readonly streetView: {
        readonly show: boolean;
        readonly params?: IStreetViewParams;
    }
}

class MapPage extends Component<IMapPageProps, IMapPageState> {
    private printStyleElement: HTMLElement | undefined;
    constructor(props: IMapPageProps) {
        super(props);

        this.state = {
            drawOnMap: false,
            streetView: {
                show: false
            }
        };

        this.printStyleElement = undefined;
    }

    public componentDidMount(): void {
        this.printStyleElement = document.createElement('style');
        this.printStyleElement.setAttribute('media', 'print');
        this.printStyleElement.innerHTML = '@page { size: landscape; }';
        document.body.appendChild(this.printStyleElement);

        const actionButton = document.getElementById('select-action-button');
        if (actionButton) {
            actionButton.classList.add('active-action');
        }

        this.applyFilters();
    }

    public componentWillUnmount(): void {
        if (this.printStyleElement) {
            document.body.removeChild(this.printStyleElement);
        }
    }

    private applyFilters(): void {
        const { filters } = this.props;
        // if there are filters -> call the correct query
        if (filters && Object.entries(filters).length > 0) {
            const pageIndex = 1;
            const forMap = true;
            this.props.getWaterSourcesByFilter(filters, pageIndex, forMap);
        }
        else {
            this.props.getWaterSources({});
        }
    }

    private isEqual(a: IWaterSourceSelectedFilters, b: IWaterSourceSelectedFilters): boolean {
        if (a === b) {
            return true;
        }

        const serialize = (obj: Record<string, unknown>): string => {
            const fields = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
            const result = fields.join(',');
            return result;
        }

        const a1 = serialize(a);
        const b1 = serialize(b);
        return a1 === b1;
    }

    public componentDidUpdate(prevProps: IMapPageProps): void {
        if (!this.isEqual(prevProps.filters, this.props.filters)) {
            // if filters change
            this.applyFilters();
        }

        if (this.props.mapClickAction !== prevProps.mapClickAction) {
            switch (this.props.mapClickAction) {
                case 'MEASURE_DISTANCE':
                    this.setState({
                        drawOnMap: GeometryType.LINE_STRING
                    })
                    break;

                case 'DRAW_POLYGON':
                    this.setState({
                        drawOnMap: GeometryType.POLYGON
                    })
                    break;

                case 'DRAW_WATER_PIPE':
                    this.setState({
                        drawOnMap: 'WaterPipe'
                    })
                    break;

                case 'DRAW_THOROUGHFARE':
                    this.setState({
                        drawOnMap: 'Thoroughfare'
                    })
                    break;

                default:
                    this.setState({
                        drawOnMap: false
                    })
                    break;
            }
        }
    }

    public render(): JSX.Element {
        const handleStreetViewClose = (): void => {
            this.setState({ streetView: { show: false, params: undefined } });
        };
        return (
            <React.Fragment>
                <div id="map-page" className="wm-map-page">
                    <Map
                        mapTileServer={this.props.mapSettings.tileServer}
                        mapCentre={this.props.mapSettings.view.centre}
                        preventReRender={!this.props.renderPoints}
                        data={{ waterSources: this.props.waterSources }}
                        renderClusters={true}
                        enableMouseCoordinates={this.props.mapClickAction === 'MEASURE_DISTANCE'}
                        showLineLength={this.props.mapClickAction === 'MEASURE_DISTANCE'}
                        editPolygons={this.props.mapClickAction === 'EDIT_POLYGON'}
                        enableDrawOnMap={this.state.drawOnMap}
                        clusterStyle={this.clusterStyle}
                        customControlButtons={this.actionButtons}
                        animateTo={this.props.renderAnimateTo}
                        selectedFeatureId={this.props.selectWaterSourceNodeId}
                        polygons={this.generatePolygons()}
                        enabledDeletionOnMap={this.setDeletionType()}
                        waterPipes={this.generateWaterPipes()}
                        joiningLines={this.generateJoiningLines()}
                        thoroughfares={this.generateThoroughfares()}
                        customCursor={this.generateCursor()}
                        selectHandler={this.handleSelect}
                        clickHandler={this.handleClick}
                        pointRenderHandler={this.handlePointRender}
                        drawEndHandler={this.drawEndHandler}
                        editPolygonsHandler={this.boundaryDrawEndHandler}
                        waterPipesDrawEndHandler={this.waterPipesDrawEndHandler}
                        thoroughfaresDrawEndHandler={this.thoroughfaresDrawEndHandler}
                        mapMoveHandler={this.handleMapMove}
                        deleteWaterPipesHandler={this.handleRemoveWaterPipe}
                        deleteThoroughfaresHandler={this.handleRemoveThoroughfare}
                        onTileServerFallback={this.handleTileServerFallback}
                    />
                    <MapKey />
                    <MapWarning open={this.props.mapFallbackWarning} />
                </div>
                <StreetView isOpen={this.state.streetView.show} params={this.state.streetView.params} onClose={handleStreetViewClose} />
            </React.Fragment>
        );
    }

    private handlePointRender = (context: Map): void => {
        if (context.props.data) {
            const { selectedRoute, selectedScheme, selectedWaterSource } = this.props;
            const waterSources = selectedRoute?.waterSources ?? selectedScheme?.waterSources;
            renderWaterSources(context, selectedWaterSource, waterSources);
        }
        this.props.setRenderPoints(false);
    };

    private handleCreateWaterSourceMapClick = (coordinates: [number, number]): void => {
        if (coordinates) {
            this.props.setCreateFromMapLocation(coordinates);
            this.props.onCreateWaterSource?.();
        }
    };

    private handleClick = (e: MapBrowserPointerEvent): void => {
        const coordinate = (e.coordinate as [number, number]);

        if (this.props.movingWaterSource) {
            return this.props.setMovingWaterSource(false, coordinate);
        }

        if (this.props.mapClickAction === 'CREATE_WATER_SOURCE') {
            this.handleCreateWaterSourceMapClick(coordinate);
        }

        if (this.props.mapClickAction === 'STREET_VIEW') {
            const lonLat = proj.toLonLat(coordinate, this.props.mapSettings.projection.code);
            this.setState({
                streetView: {
                    show: true,
                    params: {
                        lat: lonLat[1],
                        lng: lonLat[0]
                    }
                }
            });
        }
    };

    private handleSelect = (e: SelectEvent, context: Map): void => {
        if (this.state.drawOnMap || this.props.mapClickAction === 'CREATE_WATER_SOURCE' || this.props.mapClickAction === 'STREET_VIEW') {
            return;
        }

        if (e.selected?.length > 0) {
            const feature = e.selected[0];
            const { waterSourceNodeId, isActive, isOperable, isDefective, category, status, inspectionCount, tags, hasFocus } = feature.getProperties() as WaterSourceFeatureData;
            const inputStyles = waterSourceStyleInfo(isActive, isOperable.value, isDefective.value, true, category, status, inspectionCount, tags, hasFocus);
            const style = pointStyle(inputStyles, context.styleCache, true);
            feature.setStyle(style);
            this.props.onSelectWaterSource?.(parseNodeId(waterSourceNodeId));
        }

        if (e.selected.length === 0 && e.deselected.length > 0 && !this.props.movingWaterSource) {
            if (this.props.editingSelectedWaterSource) {
                this.props.setIsEditingWaterSourceAborted(true);
            }
            this.props.onClearWaterSource?.();
        }

        if (e.deselected?.length > 0 && e.deselected[0]) {
            const feature = e.deselected[0];
            const { isActive, isOperable, isDefective, category, status, inspectionCount, tags, hasFocus } = feature.getProperties() as WaterSourceFeatureData;
            const inputStyles = waterSourceStyleInfo(isActive, isOperable.value, isDefective.value, false, category, status, inspectionCount, tags, hasFocus);
            const styles = pointStyle(inputStyles, context.styleCache);
            feature.setStyle(styles);
        }
    };

    private setDeletionType(): false | MapDrawGeometry {
        switch (this.props.mapClickAction) {
            case 'DELETE_WATER_PIPE':
                return 'WaterPipe'
            case 'DELETE_THOROUGHFARE':
                return 'Thoroughfare'
            default:
                return false;
        }
    }

    private drawEndHandler = (formattedLength: string, _: number, polygonCoords: Coordinate[]): void => {
        const { mapClickAction } = this.props
        if (mapClickAction === 'MEASURE_DISTANCE') {
            this.props.onMeasuredDistance?.(formattedLength);
        }
        if (this.props.selectedScheme && mapClickAction === 'DRAW_POLYGON') {
            const boundary = polygonCoords.map<ICoordinate>(([x, y]) => ({ x, y }));
            this.props.onChangeSchemeBoundary?.(this.props.selectedScheme.schemeNodeId, boundary);
            this.props.onMapSelectClick?.();
        }
    };

    private boundaryDrawEndHandler = (coords: Coordinate[]): void => {
        if (this.props.selectedScheme) {
            const boundary = coords.map<ICoordinate>(([x, y]) => ({ x, y }));
            this.props.onChangeSchemeBoundary?.(this.props.selectedScheme.schemeNodeId, boundary);
        }
    };

    private waterPipesDrawEndHandler = (_: string, endpoints: Coordinate[]): void => {
        if (this.props.selectedScheme && this.props.mapClickAction === 'DRAW_WATER_PIPE') {
            const pipeCoordinates: IPoint[] = [];
            endpoints.forEach((coord) => {
                const pointToAdd: IPoint = {
                    x: coord[0], // first element is x coord
                    y: coord[1] // second element is y coord
                }
                pipeCoordinates.push(pointToAdd);
            });
            this.props.createWaterPipe(this.props.selectedScheme.schemeNodeId, pipeCoordinates);
        }
    };

    private handleRemoveWaterPipe = (feature: Feature): void => {
        const { selectedScheme } = this.props;
        if (selectedScheme) {
            this.props.removeWaterPipe(selectedScheme, feature);
        }
    };

    private thoroughfaresDrawEndHandler = (_: string, endpoints: Coordinate[]): void => {
        if (this.props.selectedScheme && this.props.mapClickAction === 'DRAW_THOROUGHFARE') {
            const thoroughfareCoordinates: IPoint[] = [];
            endpoints.forEach((coord) => {
                const pointToAdd: IPoint = {
                    x: coord[0], // first element is x coord
                    y: coord[1] // second element is y coord
                }
                thoroughfareCoordinates.push(pointToAdd);
            });
            this.props.createThoroughfare(this.props.selectedScheme.schemeNodeId, thoroughfareCoordinates);
        }
    };

    private handleRemoveThoroughfare = (feature: Feature): void => {
        const { selectedScheme } = this.props;
        if (selectedScheme) {
            this.props.removeThoroughfare(selectedScheme, feature);
        }
    };

    private handleTileServerFallback = (showWarning: boolean): void => {
        this.props.onTileServerFallback?.(showWarning);
    };

    private handleMapMove = (mapCenterCoords: number[] | undefined): void => {
        this.props.setMapCenterCoords(mapCenterCoords)
    };

    private generatePolygons(): PolygonInfo[] | undefined {
        const { selectedScheme } = this.props
        if (selectedScheme?.boundary) {
            return [{
                data: {
                    reference: selectedScheme.title
                },
                coords: selectedScheme.boundary.exteriorRing.coordinates.map((point) => [point.x, point.y])
            }];
        }
        return undefined
    }

    private generateWaterPipes = (): LineNodeFeature[] => {
        const resolveCoordinates = ({ waterPipeNodeId, path }: IWaterPipe): LineNodeFeature => {
            return {
                nodeId: waterPipeNodeId,
                path: path?.coordinates ?? []
            };
        };
        return this.props.selectedScheme?.waterPipes.map(resolveCoordinates) ?? [];
    };

    private generateJoiningLines = (): LineNodeFeature[] => {
        return this.props.selectedRoute?.showOnMap ? [{
            nodeId: this.props.selectedRoute.routeNodeId ?? "",
            path: this.props.selectedRoute.waterSources.map(waterSource => waterSource.location.coordinates) ?? []
        }] : [];
    };

    private generateThoroughfares = (): LineNodeFeature[] => {
        const resolveCoordinates = ({ thoroughfareNodeId, path }: IThoroughfare): LineNodeFeature => {
            return {
                nodeId: thoroughfareNodeId,
                path: path?.coordinates ?? []
            };
        }
        return this.props.selectedScheme?.thoroughfares.map(resolveCoordinates) ?? [];
    };

    private generateCursor = (): string | undefined => {
        switch (this.props.mapClickAction) {
            case 'CREATE_WATER_SOURCE':
                return `url('data:image/svg+xml;utf8,<svg width="16px" height="16px" fill="rgb(0, 0, 0)" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.99,6.99h-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2c-0.55,0-1,0.45-1,1s0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2h2c0.55,0,1-0.45,1-1S11.54,6.99,10.99,6.99z M7.99-0.01c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z M7.99,13.99c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,13.99,7.99,13.99z"/></svg>'), cell`
            case 'STREET_VIEW':
                return `url('data:image/svg+xml;utf8,<svg width="16px" height="16px" fill="rgb(0, 0, 0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15,14.62l-4-2.4V9.77c-0.32,0.09-0.66,0.15-1,0.18v2.27l-4,2.4V8.71C5.62,8.4,5.28,8.05,5,7.65v6.97l-4-2.4V8c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1V1.38l3.15,1.89C4.23,2.93,4.33,2.61,4.47,2.3L0.76,0.07l0,0.01C0.68,0.03,0.59,0,0.5,0C0.22,0,0,0.22,0,0.5v12c0,0.18,0.1,0.33,0.25,0.42l0,0.01l5,3l0-0.01C5.32,15.97,5.41,16,5.5,16s0.18-0.03,0.25-0.08l0,0.01l4.74-2.85l4.74,2.85l0-0.01C15.32,15.97,15.41,16,15.5,16c0.28,0,0.5-0.22,0.5-0.5v-3.78C15.7,11.89,15.37,12,15,12V14.62z M2,5c0,0.55,0.45,1,1,1s1-0.45,1-1c0-0.55-0.45-1-1-1S2,4.45,2,5z M8,4C7.45,4,7,4.45,7,5c0,0.55,0.45,1,1,1s1-0.45,1-1C9,4.45,8.55,4,8,4z M15.75,3.08L15.75,3.08l-1.19-0.72c0.18,0.43,0.29,0.9,0.36,1.38L15,3.78v3.39l1,1V3.5C16,3.32,15.9,3.17,15.75,3.08z M10,2C9.45,2,9,2.45,9,3c0,0.55,0.45,1,1,1s1-0.45,1-1C11,2.45,10.55,2,10,2z M13.3,6.89C13.74,6.19,14,5.38,14,4.5C14,2.01,11.99,0,9.5,0S5,2.01,5,4.5S7.01,9,9.5,9c0.88,0,1.69-0.26,2.39-0.7l2.41,2.41C14.47,10.89,14.72,11,15,11c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L13.3,6.89zM9.5,8C7.57,8,6,6.43,6,4.5S7.57,1,9.5,1S13,2.57,13,4.5S11.43,8,9.5,8z"/></svg>'), zoom-in`
            default:
                return undefined;
        }
    };

    private actionButtons: MapControlButton[] = ((): MapControlButton[] => {
        const handleSelectClick = (): void => {
            this.props.onMapSelectClick?.();
        };
        const handleCreateWaterSourceClick = (): void => {
            this.props.onMapCreateWaterSourceClick?.();
        };
        const handleMeasureDistanceClick = (): void => {
            this.props.onMapMeasureDistanceClick?.();
        };
        const handleOpenStreetViewClick = (): void => {
            this.props.onMapOpenStreetViewClick?.();
        };
        const handleClearFiltersClick = (): void => {
            this.props.onMapClearFiltersClick?.();
        };

        const actions: MapControlButton[] = [
            {
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><polygon fill-rule="evenodd" clip-rule="evenodd" points="-0.01,6.66 7.33,8.66 9.33,15.99 15.99,-0.01"/></svg>',
                id: 'map-action-select',
                title: 'Selection tool (Default)',
                action: handleSelectClick
            },
            {
                icon: '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.99,6.99h-2v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2c-0.55,0-1,0.45-1,1s0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2h2c0.55,0,1-0.45,1-1S11.54,6.99,10.99,6.99z M7.99-0.01c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S12.41-0.01,7.99-0.01z M7.99,13.99c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S11.31,13.99,7.99,13.99z"/></svg>',
                id: 'map-action-createWaterSource',
                title: 'Create a water source',
                action: handleCreateWaterSourceClick
            },
            {
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M160 288h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56v-64h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56V96h-56c-4.42 0-8-3.58-8-8V72c0-4.42 3.58-8 8-8h56V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 2.77.91 5.24 1.57 7.8L160 329.38V288zm320 64h-32v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-41.37L24.2 510.43c2.56.66 5.04 1.57 7.8 1.57h448c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z"/></svg>',
                id: 'map-action-measureDistance',
                title: 'Measure distance',
                action: handleMeasureDistanceClick
            },
            {
                icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15,14.62l-4-2.4V9.77c-0.32,0.09-0.66,0.15-1,0.18v2.27l-4,2.4V8.71C5.62,8.4,5.28,8.05,5,7.65v6.97l-4-2.4V8c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1V1.38l3.15,1.89C4.23,2.93,4.33,2.61,4.47,2.3L0.76,0.07l0,0.01C0.68,0.03,0.59,0,0.5,0C0.22,0,0,0.22,0,0.5v12c0,0.18,0.1,0.33,0.25,0.42l0,0.01l5,3l0-0.01C5.32,15.97,5.41,16,5.5,16s0.18-0.03,0.25-0.08l0,0.01l4.74-2.85l4.74,2.85l0-0.01C15.32,15.97,15.41,16,15.5,16c0.28,0,0.5-0.22,0.5-0.5v-3.78C15.7,11.89,15.37,12,15,12V14.62z M2,5c0,0.55,0.45,1,1,1s1-0.45,1-1c0-0.55-0.45-1-1-1S2,4.45,2,5z M8,4C7.45,4,7,4.45,7,5c0,0.55,0.45,1,1,1s1-0.45,1-1C9,4.45,8.55,4,8,4z M15.75,3.08L15.75,3.08l-1.19-0.72c0.18,0.43,0.29,0.9,0.36,1.38L15,3.78v3.39l1,1V3.5C16,3.32,15.9,3.17,15.75,3.08z M10,2C9.45,2,9,2.45,9,3c0,0.55,0.45,1,1,1s1-0.45,1-1C11,2.45,10.55,2,10,2z M13.3,6.89C13.74,6.19,14,5.38,14,4.5C14,2.01,11.99,0,9.5,0S5,2.01,5,4.5S7.01,9,9.5,9c0.88,0,1.69-0.26,2.39-0.7l2.41,2.41C14.47,10.89,14.72,11,15,11c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L13.3,6.89zM9.5,8C7.57,8,6,6.43,6,4.5S7.57,1,9.5,1S13,2.57,13,4.5S11.43,8,9.5,8z"/></svg>',
                id: 'map-action-openStreetView',
                title: 'Street view',
                action: handleOpenStreetViewClick
            },
            {
                icon: '<svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"/></svg>',
                id: "map-action-clearFilters",
                title: "Clear Filters",
                action: handleClearFiltersClick
            }
        ];

        if (this.props.accessControl?.manageWaterSources) {
            return actions;
        }

        const [head, , ...tail] = actions;
        return [head, ...tail];
    })();

    private clusterStyle: ClusterStyleInfo = {
        fillColor: '#ffcf21'
    };
}

export type { IMapPageProps };
export default MapPage;