import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { ThemeProvider } from "@material-ui/core/styles"

import AppWindowContext from '../../components/appWindowContext';
import GlobalDialog from "../../components/utils/globalDialog";
import GlobalNotice from '../../components/shared/globalNotice';
import GlobalToast from "../../components/shared/toast/global"

import AppWindowPage from '../../pages/AppWindowPage';
import ConfigurationPage from '../../pages/configurationPage';
import DashboardPage from '../../pages/dashboardPage';
import NotificationsPage from "../../pages/notificationsPage";
import WaterSourcePage from "../../pages/waterSourcePage";

import { desktop } from "../../themes"

import SearchRouter from '../searchRouter';
import AdminRouter from './components/AdminRouter';
import DesktopAppBar from "./components/desktopAppBar";
import {
    PATH_ADMIN_ROOT,
    PATH_APP_ROOT,
    PATH_CONFIGURATION,
    PATH_DASHBOARD,
    PATH_NOTIFICATIONS,
    PATH_SEARCH_ROOT,
    PATH_WATERSOURCE
} from './hooks/useNavigation';

import "../../../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css";
import "../../../node_modules/@blueprintjs/core/lib/css/blueprint.css";
import "../../../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "../../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css";
import "../../scss/index.scss";

const DesktopRouter = (): JSX.Element => {
    return (
        <React.Fragment>
            <ScopedCssBaseline />
            <ThemeProvider theme={desktop}>
                <AppWindowContext>
                    <DesktopAppBar />
                    <Switch>
                        <Route path={`${PATH_WATERSOURCE}/:waterSourceNodeId`}>
                            <WaterSourcePage />
                        </Route>
                        <Route path={PATH_SEARCH_ROOT}>
                            <SearchRouter />
                        </Route>
                        <Route path={PATH_NOTIFICATIONS}>
                            <NotificationsPage />
                        </Route>
                        <Route path={PATH_ADMIN_ROOT}>
                            <AdminRouter />
                        </Route>
                        <Route path={PATH_APP_ROOT} exact>
                            <AppWindowPage />
                        </Route>
                        <Route path={PATH_CONFIGURATION}>
                            <ConfigurationPage />
                        </Route>
                        <Route path={PATH_DASHBOARD}>
                            <DashboardPage />
                        </Route>
                    </Switch>
                    <GlobalToast />
                    <GlobalNotice />
                    <GlobalDialog />
                </AppWindowContext>
            </ThemeProvider>
        </React.Fragment>
    );
}

export default DesktopRouter;