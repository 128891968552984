import 'babel-polyfill';
import React, { Component } from 'react';
import Map from 'ol/Map';
import MousePosition from 'ol/control/MousePosition';
import control from 'ol/control';
import View from 'ol/View';
import GeometryType from 'ol/geom/GeometryType';
import DragPan from 'ol/interaction/DragPan';
import Feature, { FeatureLike } from 'ol/Feature';
import { Control, Zoom, Rotate } from 'ol/control.js';
import { Polygon, LineString, Geometry, Point } from 'ol/geom.js';
import { Style, Fill, Stroke, Text, Circle as CircleStyle, Icon } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ClusterSource from 'ol/source/Cluster'
import Select, { SelectEvent } from 'ol/interaction/Select';
import { click } from 'ol/events/condition';
import { Draw, Modify, Snap } from 'ol/interaction.js';
import { getArea } from 'ol/sphere.js';
import { unByKey } from 'ol/Observable.js';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import MapBrowserPointerEvent from 'ol/MapBrowserPointerEvent';
import { Coordinate } from 'ol/coordinate';

import {
    pointFeature,
    polygonFeature,
    formatLength,
    clusterStyle,
    pointStyle,
    waterSourceStyleInfo,
    ClusterStyleInfo,
    WaterSourceFeatureData,
    vectorSource,
    defaultProjection
} from '../../../lib/map/Map';
import * as coords from "../../../lib/coordinates";
import { ICoordinate, IMapWaterSource } from '../../../store/types';
import { ITileServerParams, IMapView, IMapSettings, PrimaryTileServer } from '../../../store/types/map';
import StyleCache from '../StyleCache';
import { TileServerType } from '../TileServerType';
import { MapLayerFactory } from '../MapLayerFactory';
import './Map.scss';
import styles from './Map.module.scss';
import lineDirectionArrow from '../../icons/assets/line-direction-arrow.png';
import { getCenter } from 'ol/extent';
import { createMapLayer } from '../useTileLayer';

export interface MapControlButton {
    readonly icon: string;
    readonly id: string;
    readonly title: string;
    readonly action: () => void;
}

export interface MapData {
    readonly waterSource?: IMapWaterSource;
    readonly waterSources?: (IMapWaterSource | undefined)[];
}

export interface PolygonInfo {
    data: {
        reference: string
    };
    coords: [number, number][]
}

export type MapDrawGeometry = GeometryType | "Thoroughfare" | "WaterPipe";

const clusterSource = (points: VectorSource): ClusterSource => {
    return new ClusterSource({
        distance: 75,
        source: points
    });
};

const MAP_EXTENT_TOLERANCE = 1.3;
const MAP_SINGLE_POINT_RESOLUTION = 2;
const MAP_MINIMUM_RESOLUTION = 1.5;

export interface LineNodeFeature {
    readonly nodeId: string;
    readonly path: ICoordinate[];
}

export interface ICadMapProps {
    readonly id?: string;
    readonly animateTo?: number[];
    readonly clusterStyle?: ClusterStyleInfo;
    readonly customControlButtons?: MapControlButton[];
    readonly customCursor?: string;
    readonly data: MapData;
    readonly editPolygons?: boolean;
    readonly enabledDeletionOnMap?: false | MapDrawGeometry;
    readonly enableDrawOnMap?: false | MapDrawGeometry;
    readonly enableMouseCoordinates?: boolean;
    readonly mapCentre: ICoordinate;
    readonly mapTileServer: PrimaryTileServer;
    readonly preventReRender?: boolean;
    readonly polygons?: PolygonInfo[];
    readonly renderClusters?: boolean;
    readonly selectedFeatureId?: string;
    readonly showLineLength?: boolean;
    readonly thoroughfares?: LineNodeFeature[];
    readonly waterPipes?: LineNodeFeature[];
    readonly joiningLines?: LineNodeFeature[];
    readonly clickHandler?: (evt: MapBrowserPointerEvent, context: CadMap) => void;
    readonly deleteThoroughfaresHandler?: (oldFeature: Feature) => void;
    readonly deleteWaterPipesHandler?: (oldFeature: Feature) => void;
    readonly drawEndHandler?: (length: string, area: number, geometryCoordinates: Coordinate[]) => void;
    readonly editPolygonsHandler?: (geometryCoordinates: Coordinate[]) => void;
    readonly mapMoveHandler?: (coordinates: Coordinate | undefined) => void;
    readonly pointRenderHandler?: (context: CadMap) => void;
    readonly selectHandler?: (e: SelectEvent, context: CadMap) => void;
    readonly thoroughfaresDrawChangeHandler?: (length: string) => void;
    readonly thoroughfaresDrawEndHandler?: (length: string, endpoints: Coordinate[]) => void;
    readonly waterPipeDrawChangeHandler?: (length: string) => void;
    readonly waterPipesDrawEndHandler?: (length: string, endpoints: Coordinate[]) => void;
    readonly onTileServerFallback?: (showWarning: boolean) => void;
}

interface ICadMapState {
    readonly coordinate: number[];
    readonly lineLength: string;
}

type ViewSettings =
    Pick<IMapView, "extent"> &
    Pick<IMapSettings, "projection"> & {
        readonly zoom: number;
    };

const initialZoom = 16;
const zoomThreshold = 18;

class CadMap extends Component<ICadMapProps, ICadMapState> {
    public styleCache: StyleCache;

    private mapRef: React.RefObject<HTMLDivElement>;
    private map: Map | undefined;
    private select: Select | undefined;
    private viewSettings: ViewSettings;
    private pointSource: VectorSource;
    private clusterSource: ClusterSource;
    private polygonSource: VectorSource;
    private persistantDrawSource: VectorSource;
    private waterPipesSource: VectorSource;
    private joiningLinesSource: VectorSource;
    private thoroughfaresSource: VectorSource;
    private waterPipesLayer: VectorLayer;
    private joiningLinesLayer: VectorLayer;
    private thoroughfaresLayer: VectorLayer;
    private persistantDrawLayer: VectorLayer;
    private drawSource: VectorSource;
    private drawLayer: VectorLayer;
    private pointLayer: VectorLayer;
    private polygonLayer: VectorLayer;
    private clusterLayer?: VectorLayer;
    private zoomOutIcon: HTMLSpanElement;
    private zoomInIcon: HTMLSpanElement;
    private sketch: Feature | undefined;
    private listener: any;
    private draw: Draw | undefined;
    private pipeDraw: Draw | undefined;
    private thoroughfareDraw: Draw | undefined;
    private snap: Snap | undefined;
    private waterPipesSnap: any;
    private thoroughfaresSnap: any;
    private clickEvent: any;
    private peristantDrawLayer: any;
    private modify: Modify | undefined;

    constructor(props: ICadMapProps) {
        super(props);
        this.mapRef = React.createRef();
        this.map = undefined;
        this.select = undefined;
        this.styleCache = new StyleCache();
        this.viewSettings = {
            extent: {
                max: {
                    x: 612435.55,
                    y: 1234954.16
                },
                min: {
                    x: -90619.29,
                    y: 10097.13
                }
            },
            projection: defaultProjection,
            zoom: initialZoom
        };

        this.waterPipesSource = vectorSource();
        this.waterPipesLayer = new VectorLayer({
            source: this.waterPipesSource,
            maxResolution: props.renderClusters ? 1.50 : undefined,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#3333ff',
                    width: 4
                })
            })
        });

        this.joiningLinesSource = vectorSource();
        this.joiningLinesLayer = new VectorLayer({
            source: this.joiningLinesSource,
            style: (feature: FeatureLike): Style[] => {
                const geometry = feature.getGeometry() as LineString;
                const styles = [
                    new Style({
                        stroke: new Stroke({
                            color: '#e73930',
                            width: 2
                        })
                    }),
                ];
                geometry?.forEachSegment((start, end) => {
                    const [startX, startY] = start;
                    const [endX, endY] = end;
                    const midPointX = (startX + endX) / 2;
                    const midPointY = (startY + endY) / 2;
                    const dx = endX - startX;
                    const dy = endY - startY;
                    const rotation = Math.atan2(dy, dx);
                    styles.push(
                        new Style({
                            geometry: new Point([midPointX, midPointY]),
                            image: new Icon({
                                src: lineDirectionArrow,
                                anchor: [0.75, 0.5],
                                rotateWithView: true,
                                rotation: -rotation,
                            })
                        })
                    );
                });
                return styles;
            }
        });

        this.thoroughfaresSource = vectorSource();
        this.thoroughfaresLayer = new VectorLayer({
            source: this.thoroughfaresSource,
            maxResolution: props.renderClusters ? 1.50 : undefined,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#C0C0C0',
                    width: 4
                })
            })
        });

        this.persistantDrawSource = vectorSource();
        this.persistantDrawLayer = new VectorLayer({
            source: this.persistantDrawSource,
            style: (f: FeatureLike): Style => {
                const s = new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#e73930',
                        width: 2
                    }),
                    image: new CircleStyle({
                        radius: 7,
                        fill: new Fill({
                            color: '#e73930'
                        })
                    }),
                    text: new Text({
                        text: "",
                        fill: new Fill({ color: "#273c4e" }),
                        stroke: new Stroke({ color: "#273c4e", width: 0.75 }),
                        offsetY: 15
                    })
                });
                s.getText().setText(f.get("reference"));
                return s;
            }
        });

        this.drawSource = new VectorSource({ features: [] });
        this.drawLayer = new VectorLayer({
            source: this.drawSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                })
            })
        });

        this.polygonSource = vectorSource();
        this.polygonLayer = new VectorLayer({
            source: this.polygonSource,
            maxResolution: props.renderClusters ? 1.50 : undefined,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#000',
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#000'
                    })
                })
            })
        });

        this.pointSource = vectorSource();
        this.pointLayer = new VectorLayer({
            source: this.pointSource,
            maxResolution: props.renderClusters ? 1.50 : undefined,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)'
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                })
            })
        });

        this.clusterSource = clusterSource(this.pointSource)
        if (props.renderClusters) {
            this.clusterLayer = new VectorLayer({
                source: this.clusterSource,
                minResolution: 1.51,
                style: clusterStyle(props.clusterStyle)
            });
        }

        this.state = {
            coordinate: [],
            lineLength: ""
        }

        this.zoomOutIcon = document.createElement('span');
        this.zoomOutIcon.innerHTML = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.99,5.99c-0.55,0-1,0.45-1,1s0.45,1,1,1h6c0.55,0,1-0.45,1-1s-0.45-1-1-1H3.99z M15.55,13.43l-2.67-2.68c0.7-1.09,1.11-2.38,1.11-3.76c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.39,0,2.68-0.42,3.76-1.11l2.68,2.67c0.27,0.27,0.65,0.44,1.06,0.44c0.83,0,1.5-0.67,1.5-1.5C15.99,14.08,15.82,13.7,15.55,13.43z M6.99,11.99c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5S9.75,11.99,6.99,11.99z"/></svg>';
        this.zoomInIcon = document.createElement('span');
        this.zoomInIcon.innerHTML = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99,5.99v-2c0-0.55-0.45-1-1-1s-1,0.45-1,1v2h-2c-0.55,0-1,0.45-1,1s0.45,1,1,1h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2h2c0.55,0,1-0.45,1-1s-0.45-1-1-1H7.99z M15.55,13.43l-2.67-2.68c0.7-1.09,1.11-2.38,1.11-3.76c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.39,0,2.68-0.42,3.76-1.11l2.68,2.67c0.27,0.27,0.65,0.44,1.06,0.44c0.83,0,1.5-0.67,1.5-1.5C15.99,14.08,15.82,13.7,15.55,13.43z M6.99,11.99c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5S9.75,11.99,6.99,11.99z"/></svg>';

        this.clickHandlerWrapper = this.clickHandlerWrapper.bind(this);
        this.evalClickHandler = this.evalClickHandler.bind(this);
        this.evalDeletion = this.evalDeletion.bind(this);
        this.evalDrawHandler = this.evalDrawHandler.bind(this);
        this.evalDrawWaterPipesHandler = this.evalDrawWaterPipesHandler.bind(this);
        this.evalDrawThoroughfaresHandler = this.evalDrawThoroughfaresHandler.bind(this);
        this.evalPolygons = this.evalPolygons.bind(this);
        this.evalThoroughfares = this.evalThoroughfares.bind(this);
        this.evalWaterPipes = this.evalWaterPipes.bind(this);
        this.evalJoiningLines = this.evalJoiningLines.bind(this);
        this.removeDrawInteractions = this.removeDrawInteractions.bind(this);
        this.renderCustomControls = this.renderCustomControls.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        const { tileLayer: layer, showWarning } = await createMapLayer(this.props.mapTileServer);
        this.map = new Map({
            target: this.mapRef.current ?? undefined,
            layers: [
                layer,
                this.pointLayer,
                ...(this.props.renderClusters && this.clusterLayer ? [this.clusterLayer] : []),
                this.polygonLayer,
                this.persistantDrawLayer,
                this.waterPipesLayer,
                this.joiningLinesLayer,
                this.thoroughfaresLayer
            ],
            controls: [
                new Zoom({
                    className: "zoom-controls",
                    zoomInLabel: this.zoomInIcon,
                    zoomOutLabel: this.zoomOutIcon
                }),
                new Rotate(),
                new MousePosition({
                    coordinateFormat: (coordinate: Coordinate | undefined = []): string => {
                        const [x, y] = coordinate;

                        return `${Math.floor(x)}, ${Math.floor(y)} | ${coords.formatGridReference({ x, y })}`;
                    },
                    projection: 'EPSG:27700'
                }),
                ...this.renderCustomControls()
            ],
            view: new View({
                extent: [this.viewSettings.extent.min.x, this.viewSettings.extent.min.y, this.viewSettings.extent.max.x, this.viewSettings.extent.max.y],
                projection: this.viewSettings.projection.code,
                center: [this.props.mapCentre.x, this.props.mapCentre.y],
                zoom: this.viewSettings.zoom
            })

        });

        this.props.onTileServerFallback?.(showWarning);

        this.pointLayer.setZIndex(100);

        this.map?.on('pointermove', (evt) => {
            if (this.props.enableMouseCoordinates) {
                const coordinate = [evt.coordinate[0], evt.coordinate[1]];
                this.setState({ coordinate });
            }
        });

        this.map?.on('moveend', (evt) => {
            this.props.mapMoveHandler?.(evt.map.getView().getCenter())
        });

        const mousePosition = document.querySelector('.ol-mouse-position');

        mousePosition?.classList.add('ol-mouse-position_hidden');

        this.mapRef.current?.addEventListener('mouseleave', () => {
            mousePosition?.classList.add('ol-mouse-position_hidden');
        });

        this.mapRef.current?.addEventListener('mouseenter', () => {
            mousePosition?.classList.remove('ol-mouse-position_hidden');
        });

        this.select = new Select({
            condition: click,
            layers: [
                this.pointLayer,
                this.drawLayer,
                this.persistantDrawLayer,
            ]
        });

        this.map?.addInteraction(this.select);
        this.select.on('select', (e) => this.props.selectHandler?.(e, this));

        this.evalClickHandler();
        this.evalWaterPipes();
        this.evalJoiningLines();
        this.evalThoroughfares();
        this.evalPolygons();
    }

    public componentDidUpdate(prevProps: ICadMapProps): void {
        if (!this.props.preventReRender) {
            this.pointSource.clear(); // clear features before they get re-rendered
            this.props.pointRenderHandler?.(this);
        }

        if (this.props.animateTo) {
            const view = this.map?.getView();
            if (view) {
                const calculateZoom = (): number | undefined => {
                    const current = view.getZoom() ?? 0;
                    return current < zoomThreshold ? zoomThreshold : undefined;
                };
                view.animate({ center: this.props.animateTo, zoom: calculateZoom() });
            }
        }

        if (this.props.selectedFeatureId) {
            const feature = this.pointLayer.getSource().getFeatures().find((feature) => feature.get("waterSourceNodeId") === this.props.selectedFeatureId);
            if (feature) {
                const { isActive, isOperable, isDefective, category, status, inspectionCount, tags, hasFocus } = feature.getProperties() as WaterSourceFeatureData;
                const style = waterSourceStyleInfo(isActive, isOperable.value, isDefective.value, true, category, status, inspectionCount, tags, hasFocus);
                const newStyle = pointStyle(style, this.styleCache, true);
                feature.setStyle(newStyle);
            }
        }

        if (this.props.waterPipes?.length !== prevProps.waterPipes?.length) {
            this.evalWaterPipes();
        }

        if (this.props.joiningLines?.length !== prevProps.joiningLines?.length) {
            this.evalJoiningLines();
        }

        if (this.props.thoroughfares?.length !== prevProps.thoroughfares?.length) {
            this.evalThoroughfares();
        }

        if (this.props.enableDrawOnMap !== prevProps.enableDrawOnMap) {
            const drawType = this.props.enableDrawOnMap;
            this.removeDrawInteractions();

            switch (drawType) {
                case 'LineString':
                case 'Polygon':
                    this.evalDrawHandler();
                    break;
                case 'WaterPipe':
                    this.evalDrawWaterPipesHandler();
                    break
                case 'Thoroughfare':
                    this.evalDrawThoroughfaresHandler();
                    break;
                default:
                    this.removeDrawInteractions();
                    break;
            }
        }

        this.evalClickHandler();
        this.evalPolygons();
    }

    public render(): JSX.Element {
        return (
            <div
                ref={this.mapRef}
                className={styles.map}
                style={{ cursor: this.props.customCursor ?? this.renderCursorType() }}
            />
        );
    }

    public addFeature(feature: Feature): void {
        this.pointSource.addFeature(feature);
    }

    public addFeatures(features: Feature[]): void {
        this.pointSource = vectorSource(features);
        this.pointLayer.setSource(this.pointSource);
        if (this.props.renderClusters) {
            this.clusterSource = clusterSource(this.pointSource);
            this.clusterLayer?.setSource(this.clusterSource);
        }
    }

    public fitFeatures(): void {
        if (this.map) {
            const extent = this.pointSource.getExtent();
            const center = getCenter(extent);
            this.map?.getView().setCenter(center);
            const calculatedResolution = this.map?.getView().getResolutionForExtent(extent) * MAP_EXTENT_TOLERANCE;
            const resolutionWithMinimum = calculatedResolution < MAP_MINIMUM_RESOLUTION ? MAP_MINIMUM_RESOLUTION : calculatedResolution;
            const resolution = resolutionWithMinimum || MAP_SINGLE_POINT_RESOLUTION;
            this.map?.getView().setResolution(resolution);
        }
    }

    public clearSelectedFeatures(): void {
        this.select?.getFeatures().clear();
    }

    public setSelectedFeature(predicate: ((feature: Feature) => boolean)): void {
        const points = this.pointSource.getFeatures();
        const selectedFeature = points.find(predicate);
        if (selectedFeature) {
            this.select?.getFeatures().push(selectedFeature);
        }
    }

    public gotoPoint(coordinate: number[]): void {
        this.map?.getView().animate({ center: coordinate });
        this.map?.updateSize();
    }

    private renderCustomControls(): control.Control[] {
        if (this.props.customControlButtons) {
            const allControls = this.props.customControlButtons.map(btn => {
                const newButton = document.createElement('BUTTON');
                newButton.innerHTML = btn.icon;
                newButton.setAttribute('title', btn.title);
                newButton.setAttribute('id', btn.id);
                newButton.addEventListener('click', btn.action, false);

                const newControl = new Control({
                    element: newButton
                });
                return newControl;
            });
            return allControls;
        }
        return [];
    }

    private renderCursorType(): string {
        if (this.props.enabledDeletionOnMap) {
            return `crosshair`
        }

        const formatCoord = (n: number | undefined | null): string => {
            return n?.toFixed(1).padStart(8, "0") ?? "?";
        };

        const formattedX = formatCoord(this.state.coordinate[0]);
        const formattedY = formatCoord(this.state.coordinate[1]);
        if (this.props.enableMouseCoordinates && !this.props.showLineLength) {
            return `url('data:image/svg+xml;utf8,<svg width="128" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="9" fill="black" font-size="12px" font-weight="bold">x: ${formattedX}</text><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">y: ${formattedY}</text></svg>'), auto`;
        }
        if (this.props.showLineLength && !this.props.enableMouseCoordinates) {
            return `url('data:image/svg+xml;utf8,<svg width="128" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">Length: ${this.state.lineLength}</text></svg>'), auto`;
        }
        if (this.props.showLineLength && this.props.enableMouseCoordinates) {
            return `url('data:image/svg+xml;utf8,<svg width="128" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg"><path stroke="black" d="m12.305343,9.644233l2.877946,4.958828l-5.755892,0l2.877946,-4.958828m0,-1.827447l-4.503828,7.755742l9.007656,0l-4.503828,-7.755742z"/><text x="20" y="9" fill="black" font-size="12px" font-weight="bold">x: ${formattedX}</text><text x="20" y="20" fill="black" font-size="12px" font-weight="bold">y: ${formattedY}</text><text x="10" y="32" fill="red" font-size="12px" font-weight="bold">Length: ${this.state.lineLength}</text></svg>'), auto`;
        }
        return 'auto';
    }

    private removeDrawInteractions(): void {
        if (this.draw) {
            this.map?.removeInteraction(this.draw);
        }
        if (this.pipeDraw) {
            this.map?.removeInteraction(this.pipeDraw);
        }
        if (this.thoroughfareDraw) {
            this.map?.removeInteraction(this.thoroughfareDraw);
        }
        this.drawSource.clear();
        this.persistantDrawSource.clear();
        this.draw = undefined;
        this.pipeDraw = undefined;
        this.thoroughfareDraw = undefined;
    }

    private evalClickHandler(): void {
        // clickhandler but nothing else
        if (this.props.clickHandler && !this.props.enableDrawOnMap && !this.props.enabledDeletionOnMap) {
            this.map?.removeInteraction(this.waterPipesSnap);
            unByKey(this.clickEvent); // unbind single click event
            this.clickEvent = this.map?.on('singleclick', e => {
                this.clickHandlerWrapper(e);
            });
            return;
            // deletion enabled but no draw
        }
        if (this.props.enabledDeletionOnMap && !this.props.enableDrawOnMap) {
            unByKey(this.clickEvent); // unbind single click event
            this.clickEvent = this.map?.on('singleclick', e => {
                this.evalDeletion(e);
            });
            return;
            // draw enabled but no deletion
        }
        if (!this.props.enabledDeletionOnMap && this.props.enableDrawOnMap) {
            this.map?.removeInteraction(this.waterPipesSnap);
            this.map?.removeInteraction(this.thoroughfaresSnap);
            unByKey(this.clickEvent); // unbind single click event
            return;
        }
    }

    private clickHandlerWrapper(evt: MapBrowserPointerEvent): void {
        const isWaterSource = (item: Record<string, any>): boolean => {
            const result = ("waterSourceId" in item);
            return result;
        };

        const features = this.map?.getFeaturesAtPixel(evt.pixel) ?? [];
        const hasWaterSources = features
            .map(f => f.getProperties())
            .some(isWaterSource);
        if (!hasWaterSources) {
            this.props.clickHandler?.(evt, this);
        }
    }

    private evalDeletion(evt: MapBrowserPointerEvent): void {
        const deleteWaterPipe = (): void => {
            // Need to implement snap in order to remove features from a source
            this.waterPipesSnap = new Snap({ source: this.waterPipesSource });
            this.map?.addInteraction(this.waterPipesSnap);

            const features = this.map?.getFeaturesAtPixel(evt.pixel);
            const featureToRemove = features?.[0];
            // remove feature from the vector source
            if (featureToRemove instanceof Feature && featureToRemove.get("vector") === "waterpipe") {
                this.waterPipesSource.removeFeature(featureToRemove);
                this.props.deleteWaterPipesHandler?.(featureToRemove);
            }
        };
        const deleteThoroughfare = (): void => {
            this.thoroughfaresSnap = new Snap({ source: this.thoroughfaresSource });
            this.map?.addInteraction(this.thoroughfaresSnap);

            const features = this.map?.getFeaturesAtPixel(evt.pixel);
            const featureToRemove = features?.[0];
            if (featureToRemove instanceof Feature && featureToRemove.get("vector") === "thoroughfare") {
                this.thoroughfaresSource.removeFeature(featureToRemove);
                this.props.deleteThoroughfaresHandler?.(featureToRemove);
            }
        };

        switch (this.props.enabledDeletionOnMap) {
            case "WaterPipe":
                deleteWaterPipe();
                break;
            case "Thoroughfare":
                deleteThoroughfare();
                break;
            default:
                break;
        }
    }

    private evalWaterPipes(): void {
        this.waterPipesSource.clear();
        const { waterPipes } = this.props;
        if (waterPipes && waterPipes.length > 0) {
            waterPipes.forEach(({ nodeId, path }) => {
                // Water pipes could be any number of lines long
                for (let i = 0; i < path.length - 1; i++) {
                    const startPoint = path[i];
                    const endPoint = path[i + 1];
                    this.waterPipesSource.addFeature(new Feature({
                        geometry: new LineString([
                            [startPoint.x, startPoint.y],
                            [endPoint.x, endPoint.y]
                        ]),
                        vector: "waterpipe",
                        nodeId
                    }));
                }
            });
        }
    }

    private evalJoiningLines(): void {
        this.joiningLinesSource.clear();
        const { joiningLines } = this.props;
        if (joiningLines && joiningLines.length > 0) {
            joiningLines.forEach(({ nodeId, path }) => {
                for (let i = 0; i < path.length - 1; i++) {
                    const startPoint = path[i];
                    const endPoint = path[i + 1];
                    if (startPoint && endPoint) {
                        this.joiningLinesSource.addFeature(new Feature({
                            geometry: new LineString([
                                [startPoint.x, startPoint.y],
                                [endPoint.x, endPoint.y]
                            ]),
                            vector: "joiningLines",
                            nodeId
                        }));
                    }
                }
            });
        }
    }

    private evalThoroughfares(): void {
        this.thoroughfaresSource.clear();
        const { thoroughfares } = this.props;
        if (thoroughfares && thoroughfares.length > 0) {
            thoroughfares.forEach(({ nodeId, path }) => {
                for (let i = 0; i < path.length - 1; i++) {
                    const startPoint = path[i];
                    const endPoint = path[i + 1];
                    this.thoroughfaresSource.addFeature(
                        new Feature({
                            geometry: new LineString([
                                [startPoint.x, startPoint.y], [endPoint.x, endPoint.y]
                            ]),
                            vector: "thoroughfare",
                            nodeId
                        }));
                }
            });
        }
    }

    private evalPolygons(): void {
        if (this.snap) {
            this.map?.removeInteraction(this.snap);
        }
        if (this.modify) {
            this.map?.removeInteraction(this.modify);
        }
        this.polygonSource.clear();

        if (this.props.polygons) {
            this.props.polygons.forEach((polygon) => {
                const polygonToAdd = polygonFeature(new Polygon([polygon.coords]), polygon.data);
                if (polygonToAdd instanceof Feature) {
                    this.polygonSource.addFeature(polygonToAdd);
                }
            });
        }

        if (this.props.editPolygons) {
            this.snap = new Snap({ source: this.polygonSource });
            this.modify = new Modify({ source: this.polygonSource });
            this.map?.addInteraction(this.snap);
            this.map?.addInteraction(this.modify);

            this.modify.on('modifyend', (e: ModifyEvent) => {
                const geometry = e.features.getArray()[0].getGeometry() as Polygon;
                const polygonCoords = geometry.getCoordinates();
                if (polygonCoords.length > 0) {
                    this.props.editPolygonsHandler?.(polygonCoords[0]);
                }
            });
        }
    }

    private evalDrawWaterPipesHandler(): void {
        if (this.props.enableDrawOnMap && this.props.enableDrawOnMap === "WaterPipe") {
            this.pipeDraw = new Draw({
                source: this.waterPipesSource,
                type: GeometryType.LINE_STRING,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#3333ff',
                        width: 4
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.7)'
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)'
                        })
                    })
                })
            })
            this.map?.addInteraction(this.pipeDraw);

            this.pipeDraw.on('drawstart', (evt: DrawEvent) => {
                this.sketch = evt.feature;
                this.listener = this.sketch.getGeometry()?.on('change', (evt: Event) => {
                    const geometry = evt.target;
                    if (geometry instanceof Geometry) {
                        if (this.props.waterPipeDrawChangeHandler) {
                            const length = formatLength(geometry);
                            this.props.waterPipeDrawChangeHandler(length);
                        }
                    }
                });
            });

            this.pipeDraw.on('drawend', (evt: DrawEvent) => {
                this.controlDragPan(false);
                this.waterPipesSource.addFeature(evt.feature);
                const geometry = evt.feature.getGeometry();
                if (geometry instanceof LineString) {
                    unByKey(this.listener);
                    if (this.props.waterPipesDrawEndHandler) {
                        const length = formatLength(geometry);
                        const startAndEndCoords = geometry.getCoordinates();
                        if (startAndEndCoords.length > 0) {
                            this.props.waterPipesDrawEndHandler(length, startAndEndCoords);
                        }
                    }
                    setTimeout(() => this.controlDragPan(true), 300)
                }
            });
        }
    }

    private controlDragPan(enable: boolean): void {
        if (enable) {
            const dragInteration = new DragPan();
            this.map?.addInteraction(dragInteration);
        } else {
            this.map?.getInteractions().forEach(interaction => {
                if (interaction && interaction instanceof DragPan) {
                    this.map?.removeInteraction(interaction);
                }
            });
        }
    }

    private evalDrawThoroughfaresHandler(): void {
        if (this.props.enableDrawOnMap && this.props.enableDrawOnMap === "Thoroughfare") {
            this.thoroughfareDraw = new Draw({
                source: this.thoroughfaresSource,
                type: GeometryType.LINE_STRING,
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: '#C0C0C0',
                        width: 4
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.7)'
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)'
                        })
                    })
                })
            })
            this.map?.addInteraction(this.thoroughfareDraw);

            this.thoroughfareDraw.on('drawstart', (evt: DrawEvent): void => {
                this.sketch = evt.feature;
                this.listener = this.sketch.getGeometry()?.on('change', (evt: Event) => {
                    const geometry = evt.target;
                    if (geometry instanceof LineString) {
                        if (this.props.thoroughfaresDrawChangeHandler) {
                            const lineLength = formatLength(geometry);
                            this.props.thoroughfaresDrawChangeHandler(lineLength);
                        }
                    }
                });
            });

            this.thoroughfareDraw.on('drawend', (evt: DrawEvent) => {
                this.controlDragPan(false);
                this.thoroughfaresSource.addFeature(evt.feature);
                const geom = evt.feature.getGeometry();
                if (geom instanceof LineString) {
                    unByKey(this.listener);
                    if (this.props.thoroughfaresDrawEndHandler) {
                        const length = formatLength(geom);
                        const startAndEndCoords = geom.getCoordinates();
                        if (startAndEndCoords.length > 0) {
                            this.props.thoroughfaresDrawEndHandler(length, startAndEndCoords);
                        }
                    }
                    setTimeout(() => this.controlDragPan(true), 300)
                }
            });
        }
    }

    private evalDrawHandler(): void {
        if (this.props.enableDrawOnMap && !this.draw && this.props.enableDrawOnMap !== "WaterPipe" && this.props.enableDrawOnMap !== "Thoroughfare") {
            this.draw = new Draw({
                source: this.drawSource,
                type: this.props.enableDrawOnMap, // LineString or Polygon
                style: new Style({
                    fill: new Fill({
                        color: 'rgba(255, 255, 255, 0.2)'
                    }),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.5)',
                        lineDash: [5, 5],
                        width: 4
                    }),
                    image: new CircleStyle({
                        radius: 5,
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.7)'
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)'
                        })
                    })
                })
            });
            this.map?.addInteraction(this.draw);

            this.draw.on('drawstart', (evt: DrawEvent): void => {
                this.sketch = evt.feature;
                this.listener = this.sketch.getGeometry()?.on('change', (evt: Event) => {
                    const geometry = evt.target;
                    if (geometry instanceof Geometry) {
                        const lineLength = formatLength(geometry);
                        if (this.props.showLineLength) {
                            this.setState({
                                lineLength: lineLength
                            });
                        }
                    }
                });
            });

            this.draw.on('drawend', (evt: DrawEvent): void => {
                this.persistantDrawSource.addFeature(evt.feature);
                const geometry = evt.feature.getGeometry();
                if (geometry instanceof LineString || geometry instanceof Polygon) {
                    unByKey(this.listener);
                    const length = formatLength(geometry);
                    const endCoord = geometry.getLastCoordinate();
                    const labelFeature = pointFeature(endCoord, { reference: length });
                    this.persistantDrawSource.addFeature(labelFeature);

                    if (this.props.drawEndHandler) {
                        const area = getArea(geometry);
                        const polygonCoords = geometry?.getCoordinates();
                        if (polygonCoords.length > 0) {
                            this.props.drawEndHandler(length, area, polygonCoords[0]);
                        }
                    }
                }
            });
        }
    }
}

export default CadMap;
