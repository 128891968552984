import { connect } from "react-redux";

import bindLookup from "../../../../../../lib/lookups";
import { IReduxState, ILookupItem } from "../../../../../../store/types";
import Editor, { EditorProps } from "./editor.view";

type ConnectedProps = Pick<EditorProps,
    "classifications" |
    "mainsSizeUnits" |
    "stations" |
    "surfaces" |
    "riskSeverities" |
    "tagOptions"
>;

const bindTagFilter = (category: ILookupItem | undefined): ((tag: ILookupItem) => boolean) => {
    if (category === undefined) {
        return (): boolean => false;
    }
    return (tag: ILookupItem): boolean => {
        switch (category?.value) {
            case 1:
                return tag.filters.includes("Hydrant");
            case 2:
                return tag.filters.includes("EmergencyWaterSupply");
            case 3:
                return tag.filters.includes("Riser");
            case 0:
                return tag.filters.includes("UnknownWaterSource");
            default:
                return false;
        }
    };
};

const mapProps = (state: IReduxState, props: { readonly category: ILookupItem }): ConnectedProps => {
    const { waterSourceClasses, mainsSizeUnits, stations, surfaces, riskSeverity, tags } = state.app.lookups ?? {};
    const isCategoryMatch = bindTagFilter(props.category);
    return {
        classifications: bindLookup(waterSourceClasses?.filter(item => item.groupingId === props.category.value) ?? []),
        mainsSizeUnits: bindLookup(mainsSizeUnits ?? []),
        riskSeverities: bindLookup(riskSeverity ?? []),
        stations: bindLookup(stations ?? []),
        surfaces: bindLookup(surfaces ?? []),
        tagOptions: bindLookup(tags?.filter(isCategoryMatch) ?? [])
    };
};

export default connect(mapProps)(Editor);