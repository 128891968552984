import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import createRouteQuery from './graphQL/createRoute';
import editRouteQuery from './graphQL/editRoute';
import updateRouteWaterSources from './graphQL/updateRouteWaterSources';

import executeQuery from '../../lib/executeQuery';
import { IRoute, IEditRouteInput, INewRouteVariables, IUpdateRouteWaterSources } from '../types';
import { setGlobalToast, setLoadingToast, setSuccessToast } from './app';
import { setError } from './errors';
import { setRenderPoints } from './map';
import { guid } from '../../lib/Utils';

export const beginCreateRoute = (variables: INewRouteVariables) => {
    return async (dispatch: ThunkDispatch<any, unknown, AnyAction>): Promise<IRoute | undefined> => {
        try {
            dispatch(setLoadingToast('Creating new route...'));

            const response = await executeQuery<{ createRoute: { route: IRoute } }>(createRouteQuery, variables);

            const { route: newRoute } = response?.createRoute ?? {};
            if (newRoute) {
                dispatch(setSuccessToast(`Route ${newRoute.routeId} created`, 5000));
            }
            return newRoute;
        } catch (err) {
            dispatch(setError('Error creating route', err));
        }
    }
};

export const beginUpdateRoute = (variables: IEditRouteInput) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Updating route...'));

            const response = await executeQuery<{ updateRoute: { route: IRoute } }>(editRouteQuery, variables);

            const { route } = response?.updateRoute ?? {};
            if (route) {
                dispatch(setGlobalToast({
                    type: 'SUCCESS',
                    message: `Route ${route.routeId} updated`,
                    showToast: true,
                    timeout: 5000
                }));
            }
        } catch (err) {
            dispatch(setError('Error updating route', err));
        }
    }
};

interface UpdateRouteMembersOptions {
    readonly refreshMap?: boolean;
}

export const beginUpdateRouteWaterSources = (routeNodeId: string, waterSources: string[], options?: UpdateRouteMembersOptions) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>): Promise<void> => {
        try {
            dispatch(setLoadingToast('Updating route...'));

            const request: IUpdateRouteWaterSources = {
                input: {
                    clientMutationId: guid(),
                    nodeId: routeNodeId,
                    data: {
                        waterSources
                    }
                }
            };
            const response = await executeQuery<{ updateRouteWaterSources: { route: IRoute } }>(updateRouteWaterSources, request);

            const { route } = response?.updateRouteWaterSources ?? {};
            if (route) {
                const { refreshMap } = options ?? {};

                if (refreshMap) {
                    dispatch(setRenderPoints(true));
                }

                dispatch(setSuccessToast(`Route ${route.routeId} water sources updated`, 5000));
            }
        }
        catch (error) {
            dispatch(setError('Error updating route water sources', error));
        }
    }
};
