import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";

import InputLabel from "./inputLabel";

interface TextInputProps {
    readonly value: any;
    readonly type?: "text" | "date" | "number";
    readonly id?: string;
    readonly label?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly multiline?: boolean;
    readonly rows?: number;
    readonly onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginBottom: theme.spacing(1.5),
    },
    inputRoot: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        color: "white",
        padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
        ["&.Mui-disabled"]: {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
            color: theme.palette.grey[600],
        },
        "&.Mui-focused": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
        "&.MuiFilledInput-underline::after": {
            borderBottomColor: theme.palette.secondary.main
        }
    },
    input: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const TextInput = (props: TextInputProps): JSX.Element => {
    const styles = useStyles();
    const { value, id, label, placeholder, required, disabled, type, multiline, rows, onChange } = props;
    const inputProps = {
        id,
        type,
        value,
        placeholder,
        required,
        disabled,
        multiline,
        rows,
        onChange
    };
    return (
        <FormControl className={styles.root} fullWidth variant="filled">
            <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
            <FilledInput {...inputProps} disableUnderline={disabled} classes={{ root: styles.inputRoot, input: styles.input }} />
        </FormControl>
    );
}

export type { TextInputProps };
export default TextInput;