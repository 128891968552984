import JSPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { LocalDateTime, DateTimeFormatter } from '@js-joda/core';

import executeQuery from '../../../lib/executeQuery';
import { longDateFormatter, shortDateFormatter } from '../../../lib/Utils';
import getWaterSourceQuery from '../../../store/actions/graphQL/getWaterSource';
import { ICoordinate, IWaterSource, IRoute } from '../../../store/types';
import { bindComparers } from '../../../lib/comparers';

export type RouteWaterSource = Pick<IWaterSource,
    "waterSourceNodeId" |
    "waterSourceId" |
    "nextInspectionDate" |
    "recommendedInspectionDate" |
    "riskSeverity" |
    "routeSortOrder" |
    "location"
>;

export interface ListItem {
    readonly [key: string]: any;
}

export interface ListItemCollectionHandler {
    (items: ListItem[]): void;
}

export interface ListItemHandler {
    (item: ListItem): void;
}

export interface ReadOnlyListConfig<T> {
    readonly titleKey: string;
    readonly titleLeftKey: string;
    readonly infoKey: string;
    readonly titleAttrKeys: (keyof T)[];
}

export interface ListContextMenuItem {
    readonly key: string;
    readonly onClick: ListItemHandler;
}

export interface ListContextMenu {
    readonly title: string;
    readonly items: ListContextMenuItem[];
}

export interface EditableListConfig<T> extends ReadOnlyListConfig<T> {
    readonly orderable?: {
        handler: ListItemCollectionHandler
    };
    readonly moreMenu?: ListContextMenu;
}

export const getWaterSource = async (nodeId: string): Promise<IWaterSource | undefined> => {
    const variables = { id: nodeId };
    return executeQuery<{ node: IWaterSource | undefined }>(getWaterSourceQuery, variables)
        .then(response => response?.node);
};

export const compareRouteSortOrder = (a: RouteWaterSource, b: RouteWaterSource): number => {
    const orderA = a.routeSortOrder ?? 0;
    const orderB = b.routeSortOrder ?? 0;
    return orderA - orderB;
};

export const compareExternalId = (a: RouteWaterSource, b: RouteWaterSource): number => {
    return a.waterSourceId.localeCompare(b.waterSourceId);
};

export const compareWaterSources = bindComparers([
    compareRouteSortOrder,
    compareExternalId
]);

export const toListItem = (waterSource: RouteWaterSource): ListItem => ({
    waterSourceNodeId: waterSource.waterSourceNodeId,
    waterSourceId: waterSource.waterSourceId,
    nextInspectionDate: waterSource.nextInspectionDate,
    riskSeverity: waterSource.riskSeverity,
    routeSortOrder: waterSource.routeSortOrder,
    title: waterSource.waterSourceId.toString(),
    riskSeverityText: `Risk severity: ${waterSource.riskSeverity?.displayText ?? '-'}`,
    nextInspectionDateText: `Next inspection: ${waterSource.nextInspectionDate?.format(longDateFormatter) ?? '-'}`
});

export const toRouteWaterSource = (listItem: ListItem, index: number): RouteWaterSource => ({
    waterSourceNodeId: listItem.waterSourceNodeId,
    waterSourceId: listItem.waterSourceId,
    nextInspectionDate: listItem.nextInspectionDate,
    riskSeverity: listItem.riskSeverity,
    routeSortOrder: index + 1,
    location: listItem.location
});

export const getCenterPointFromWaterSources = (routeWaterSources: RouteWaterSource[]): ICoordinate => {
    let minX = 0;
    let minY = 0;
    let maxX = 0;
    let maxY = 0;

    routeWaterSources.forEach(ws => {
        const { x, y } = ws.location.coordinates ?? {};

        if (minX === 0 || maxX === 0) {
            minX = x;
            maxX = x;
        } else if (x < minX) {
            minX = x;
        } else if (x > maxX) {
            maxX = x;
        }

        if (minY === 0 || maxY === 0) {
            minY = y;
            maxY = y;
        } else if (y < minY) {
            minY = y;
        } else if (y > maxY) {
            maxY = y;
        }

        return
    });

    const x = (minX + maxX) / 2;
    const y = (minY + maxY) / 2;

    return { x, y }
};

export const printRoute = (route: IRoute, imageUrl: string): void => {
    const formatNamePart = (s: string): string => {
        return s.toLowerCase().replaceAll(/[\s\\/:*?"<>|]/g, "");
    };

    const { routeId, name, station, waterSources } = route;

    const pdf = new JSPDF({ orientation: 'landscape', unit: 'px' });
    pdf.text(`Water Management Route: ${station.displayText}, ${name} (${routeId})`, 30, 30);
    pdf.text(`Water sources: ${waterSources.length}`, 30, 40)
    pdf.addImage(imageUrl, 30, 50, 0, 285);
    pdf.addPage();
    pdf.setPage(2);

    autoTable(pdf, {
        head: [['Route Order', 'Water Source Id', 'Next Inspection Date', 'Risk Severity', 'Surface', 'Location Description', 'Street', 'Hydrant Location']],
        body: waterSources.map(ws => [
            ws.routeSortOrder ?? '',
            ws.waterSourceId ?? '',
            ws.nextInspectionDate?.format(shortDateFormatter) ?? ws.recommendedInspectionDate?.format(shortDateFormatter) ?? '',
            ws.riskSeverity?.displayText ?? '',
            ws.surface?.displayText ?? '',
            ws.location.description ?? '',
            ws.location.address?.streetDescription ?? '',
            ws.hydrantLocation?.displayText ?? ''
        ])
    });

    const timestamp = LocalDateTime.now().format(DateTimeFormatter.ofPattern("uuuuMMddHHmmss"));
    const fileName = `${formatNamePart(route.station.displayText)}-${formatNamePart(route.name)}-${timestamp}.pdf`;

    pdf.save(fileName);
};
