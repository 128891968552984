import React, { useState, useEffect } from "react";
import { Input, LoadingBar } from "@3tc/shared-components";

import { jsonToCsv, downloadCsvFile } from "../../../lib/csv";
import { formatText, formatCurrency, longDateFormatter } from "../../../lib/Utils";
import { ILookups, IConnection, ISavedSearch, IQueryControl } from "../../../store/types";
import ActionButtons from "../actionButtons";
import Filter from "../filter";
import FilterResultsTable from "../filterResultsTable";
import SearchSelectorContext from "../searchSelector/searchSelectorContext";
import { formatYesNo } from "../utils";
import queryRepairsByFilter from "./query";
import { RepairSearchItem, RepairSearchFilters, RepairSelectedFilters } from "./types";
import { buildQueryFilter, buildRepairFilters } from "./utils";
import styles from "./repairSearch.module.scss";
import { ITableHeader } from "../types";
import { useTablePaging } from "../hooks";
import { SortDirections } from "../filterResultsTable/components/results";

interface RepairSearchProps {
    readonly lookups: ILookups;
    readonly hideToast: () => void;
    readonly showError: (message: string, error?: Error) => void;
    readonly showLoading: (message: string) => void;
    readonly showRepairOnMap: (repair: RepairSearchItem) => void;
    readonly showSuccess: (message: string) => void;
}

const tableHeaders: ITableHeader[] = [
    {
        displayText: "Id",
        sortBy: "id"
    },
    {
        displayText: "Water Source",
        sortBy: "waterSource"
    },
    {
        displayText: "Category",
        sortBy: "repairCategory"
    },
    {
        displayText: "Type",
        sortBy: "repairType"
    },
    {
        displayText: "Created",
        sortBy: "dateCreated"
    },
    {
        displayText: "Closed",
        sortBy: "dateClosed"
    },
    {
        displayText: "Defect",
        sortBy: "defect"
    },
    {
        displayText: "Inspection",
        sortBy: "inspection"
    },
    {
        displayText: "Actual Cost (£)",
        sortBy: "actualCost"
    },
    {
        displayText: "Paid",
        sortBy: "datePaid"
    },
    {
        displayText: "Vendor",
        sortBy: "repairVendor"
    },
    {
        displayText: "Open",
        sortBy: "isOpen"
    }
];
const tableRows = (repairs: RepairSearchItem[]): string[][] => {
    return repairs.map(repair => {
        return [
            repair.repairId.toString(),
            repair.waterSource.waterSourceId.toString(),
            repair.repairCategory.displayText,
            repair.repairType?.displayText ?? "",
            repair.dateCreated?.toLocalDate()?.format(longDateFormatter) ?? "",
            repair.dateClosed?.format(longDateFormatter) ?? "",
            repair.defect?.defectId.toString() ?? "",
            repair.inspection?.inspectionId.toString() ?? "",
            formatCurrency(repair.actualCost, false),
            repair.datePaid?.format(longDateFormatter) ?? "",
            repair.repairVendor?.displayText ?? "",
            formatYesNo(repair.isOpen)
        ];
    });
};

const empty: IConnection<RepairSearchItem> = {
    edges: [],
    items: [],
    pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        isFirstPage: false,
        isLastPage: false,
        pageCount: 0,
        pageNumber: 0,
        pageSize: 0,
        sortBy: "id",
        sortDirection: "ASCENDING"
    },
    totalCount: 0
};

const initialPaging: IQueryControl = {
    pageIndex: 1,
    pageSize: 50,
    sortBy: "id",
    sortDirection: "ASCENDING"
};

const queryRepairs = async (selectedFilters: RepairSelectedFilters, paging: IQueryControl): Promise<IConnection<RepairSearchItem>> => {
    const filter = buildQueryFilter(selectedFilters);
    return queryRepairsByFilter(filter, paging);
};

const RepairSearch = (props: RepairSearchProps): JSX.Element => {
    const { hideToast, showError, showLoading, showRepairOnMap } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<RepairSelectedFilters>({});
    const [page, setPage] = useState<IConnection<RepairSearchItem>>(empty);
    const [selectedSearch, setSelectedSearch] = useState<ISavedSearch | undefined>();
    const { items, pageInfo, totalCount } = page;
    const [
        handleSearchClick,
        handleFirstClick,
        handlePreviousClick,
        handleNextClick,
        handleLastClick,
        handleTableHeaderClick,
        paging
    ] = useTablePaging(initialPaging, pageInfo);

    useEffect(() => {
        if (paging) {
            setIsLoading(true);
            showLoading("Getting repairs...");
            queryRepairs(selectedFilters, paging)
                .then(repairs => setPage(repairs))
                .catch(() => showError("Error searching for repairs"))
                .finally(() => {
                    setIsLoading(false);
                    hideToast();
                });
        }
    }, [paging]);

    const handleFilterChange = (value: string): void => {
        if (Object.keys(selectedFilters).indexOf(value) > -1) {
            const update = { ...selectedFilters };
            delete update[value];
            setSelectedFilters(update);
            return;
        }
        setSelectedFilters({ ...selectedFilters, [value]: [] });
    };

    const handleClearClick = (): void => {
        setIsLoading(true);
        setSelectedFilters({});
        setPage(empty);
        setIsLoading(false);
    };

    const handleExportClick = (): void => {
        queryRepairs(selectedFilters, { pageIndex: 1, pageSize: totalCount, sortBy: "id", sortDirection: SortDirections.ASCENDING })
            .then(waterSources => {
                if (waterSources) {
                    const csvData = jsonToCsv(waterSources.items.map(item => ({
                        repairId: item.repairId,
                        waterSource: item.waterSource,
                        repairCategory: item.repairCategory,
                        repairType: item.repairType,
                        dateCreated: item.dateCreated,
                        dateClosed: item.dateClosed,
                        defect: item.defect,
                        inspection: item.inspection,
                        actualCost: item.actualCost,
                        datePaid: item.datePaid,
                        repairVendor: item.repairVendor,
                        open: item.isOpen ? 'Yes' : 'No'
                    })));
                    downloadCsvFile(csvData);
                } else {
                    showError("Error exporting water sources to CSV.")
                }
            })
            .catch(error => showError("Error exporting water sources to CSV.", error))
            .finally(hideToast);
    };

    const handleRowClick = (id: string): void => {
        const repair = items.find(item => item.repairId.toString() === id);
        if (repair) {
            showRepairOnMap(repair);
        }
    };

    const handleChangeSelection = (selected: ISavedSearch | undefined): void => {
        setSelectedSearch(selected);
    };

    useEffect(() => {
        if (selectedSearch) {
            setSelectedFilters(selectedSearch.queryFilter);
        }
    }, [selectedSearch, setSelectedFilters]);

    const schemeFilters: RepairSearchFilters = buildRepairFilters(props.lookups);

    return (
        <SearchSelectorContext entity="repairs" selectedFilters={selectedFilters} onChangeSelection={handleChangeSelection}>
            <div className={styles.allInputs}>
                <div className={styles.inputContainer}>
                    <Input
                        type="multilist"
                        id="selected-filters-list"
                        labelText="Search On"
                        isEditing
                        value={Object.keys(selectedFilters)}
                        responseOptions={Object.keys(schemeFilters).map(item => ({
                            value: item,
                            text: formatText(item, true, undefined, true)
                        }))}
                        onChangeHandler={handleFilterChange}
                    />
                </div>
                {Object.keys(selectedFilters).map(filter => (
                    <Filter
                        key={filter}
                        name={filter}
                        options={schemeFilters[filter]}
                        selected={selectedFilters}
                        onChangeSelection={setSelectedFilters}
                    />
                ))}
            </div>
            <ActionButtons
                totalCount={totalCount}
                onClear={handleClearClick}
                onExport={handleExportClick}
                onSearch={handleSearchClick}
            />
            {isLoading
                ? <LoadingBar loading />
                : <FilterResultsTable
                    headers={tableHeaders}
                    items={tableRows(items)}
                    pageInfo={pageInfo}
                    totalCount={totalCount}
                    onFirstClick={handleFirstClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onLastClick={handleLastClick}
                    onTableHeaderClick={handleTableHeaderClick}
                    onTableRowClick={handleRowClick}
                />
            }
        </SearchSelectorContext>
    );
};

export type { RepairSearchProps };
export default RepairSearch;