import React, { RefObject } from "react";
import cx from "classnames";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { containerClass, inputClass, labelClass } from "../../labelledField.utils";
import { EditElementProps } from "../../types";
import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import ReadOnlyElement from "../readOnlyElement";

interface TextElementProps extends EditElementProps<string> {
    readonly multiline?: boolean;
}

type TextInputProps<T = Element> = Pick<TextElementProps, "id" | "label" | "value" | "classes"> & {
    readonly textTransform?: "uppercase" | "unset";
    readonly inputRef?: RefObject<HTMLInputElement>;
    readonly onChange?: (event: React.ChangeEvent<T>) => void;
    readonly onFocus?: (event: React.FocusEvent<T>) => void;
};

const useStyles = makeStyles(createStyles({
    text: {
        textTransform: (props: Pick<TextInputProps, "textTransform">): "uppercase" | "unset" | undefined => props.textTransform
    }
}));

const MultiLineInput = (props: TextInputProps<HTMLTextAreaElement>): JSX.Element => {
    const { id, label, value, classes, textTransform, onChange, onFocus } = props;
    const styles = useStyles({ textTransform });
    return (
        <textarea
            placeholder={label}
            id={id}
            name={id}
            className={cx("input", inputClass({ classes }), styles.text)}
            value={value ?? ""}
            onChange={onChange}
            onFocus={onFocus}
            rows={5}
        />
    );
};

const SingleLineInput = (props: TextInputProps<HTMLInputElement>): JSX.Element => {
    const { id, label, value, classes, textTransform, inputRef, onChange, onFocus } = props;
    const styles = useStyles({ textTransform });
    return (
        <input
            type="text"
            placeholder={label}
            id={id}
            name={id}
            ref={inputRef}
            className={cx("input", inputClass({ classes }), styles.text)}
            value={value ?? ""}
            onChange={onChange}
            onFocus={onFocus}
        />
    );
};

const TextElement = (props: TextElementProps): JSX.Element => {
    if (props.editing) {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const value = e.target.value === ""
                ? undefined
                : e.target.value;
            props.onChange(value);
        };
        const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
            const value = e.target.value === ""
                ? undefined
                : e.target.value;
            props.onFocus?.(value);
        }
        const labelPosition = props.labelPosition ?? "TOP";
        return (
            <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
                {labelPosition === "TOP" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
                {props.multiline
                    ? <MultiLineInput {...props} onChange={handleChange} onFocus={handleFocus} textTransform="unset" />
                    : <SingleLineInput {...props} onChange={handleChange} onFocus={handleFocus} />
                }
                {labelPosition === "BOTTOM" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
            </ElementContainer>
        );
    }
    return <ReadOnlyElement id={props.id} label={props.label} noMargin={props.noMargin} classes={props.classes} value={props.value} normalcase />;
};

export type { TextElementProps };
export { MultiLineInput, SingleLineInput };
export default TextElement;