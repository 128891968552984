import React from "react";

import { NodeID, parseNodeId } from "../../../../lib/nodeIdentifier";
import { IScheme } from "../../../../store/types";
import LabelledField from "../../../labelledField";

interface SchemeLinkProps {
    readonly scheme: IScheme | undefined;
    readonly onClick?: (id: NodeID) => void;
}

const WaterSourceSchemeLink = ({ scheme, onClick }: SchemeLinkProps): JSX.Element => {
    const id = "water-source-scheme";
    const label = "Scheme";
    if (scheme) {
        const handleClick = (): void => {
            onClick?.(parseNodeId(scheme.schemeNodeId));
        };
        return (
            <LabelledField
                fieldType="link"
                id={id}
                label={label}
                classes={{ container: 'input__group input__group_side-panel', label: 'input__label' }}
                linkText={`${scheme.schemeId} - ${scheme.title}`}
                preventDefault
                onClick={handleClick}
            />
        );
    }
    return <LabelledField fieldType="readonly" id={id} label={label} value="-" classes={{ container: 'input__group input__group_side-panel', label: 'input__label' }} />;
};

export default WaterSourceSchemeLink;