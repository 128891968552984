export default `
query waterSourceControl_Query($id: ID!) {
    node(id:$id) {
        ... on IWaterSource {
            waterSourceNodeId: id
            waterSourceId: externalId
            category {
                value
                displayText
            }
            classification {
                value
                displayText
            }
            isActive
            status {
                value
                displayText
            }
            isDefective {
                value
                isOverridden
            }
            isOperable {
                value
                isOverridden
            }
            organisation {
                organisationNodeId: id
                organisationId
                shortName
                name
                isActive
            }
			inspectionFrequency
            lastInspectionDate
            nextInspectionDate  
            recommendedInspectionDate
            additionalInfo         
            location {
                address {
                    addressNodeId: id
                    addressId
                    saon {
                        description
                    }
                    paon {
                        description
                    }
                    usrn
                    streetDescription
                    locality
                    town
                    postCode
                    administrativeArea
                    uprn
                    coordinates {
                        x
                        y
                    }
                }
                coordinates {
                    x
                    y
                }
                description
            }
            station {
                displayText
                value
            }
            route {
                routeNodeId: id
                routeId
                name
                isActive
                station {
                    value
                    displayText
                }
            }
            routeSortOrder
            riskSeverity {
                displayText
                value                
            }
            hazardSeverity {
                displayText
                value
            }
            hazards {
                hazardNodeId: id
                hazardId
                hazardType {
                    value
                    displayText
                }
            }
            scheme {
                schemeNodeId: id
                schemeId
                title
            }
            notes {
                noteNodeId: id
                noteId
                timestamp
                author
                content
            }
          	... on ITagged {
                tags {
                    value
                    displayText
                    enabled
                }
            }
            ... on Hydrant {
                assetReference
                flowRate
                pressure
                mainsSize {
                  value
                  unit {
                    value
                    displayText
                  }
                }
                hydrantLocation {
                    displayText
                    value
                }
                plateDistance
                plateLocation {
                    value
                    displayText
                }
                surface {
                  value
                  displayText
                }
            }
            ... on EmergencyWaterSupply {
                accessDescription
                capacity
                isHardstanding
                isSeasonal
                lift
                owner
                pumpCarryDistances
                pumpCount
                pumpTypes {
                    value
                    displayText
                    sortOrder
                    enabled
                }
            }
            controlMeasures {
                value
                displayText
                enabled
                sortOrder
            }
            roadSpeed {
                value
                displayText
            }
            isSecondPersonRequired
            hazardInformation
            hazardFlag {
                value
                displayText
            }
            inspectionCount(includeClosed:false)
            inspections {
                inspectionNodeId: id
            }
        }
    }
}`;