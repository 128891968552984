import React from 'react';

import useSelector from '../../../lib/hooks/useSelector';
import GlobalNoticeBase from './globalNotice.view';

const GlobalNotice = (): JSX.Element => {
    const { globalNotice } = useSelector(state => state.app);

    return <GlobalNoticeBase globalNotice={globalNotice} />
}

export default GlobalNotice;