import { LocalDate } from '@js-joda/core';
import { ILookupItem, ILocation, IMeasurement } from './app';
import { WithOverride } from "./common";
import { INote } from './notes';
import { IRoute } from './routes';
import { Repair } from './repairs';
import { IInspection } from './inspections';
import { IDefect } from './defects';
import { IScheme } from './schemes';
import { IHazard } from './hazards';
import { IPageInfo } from './connections';
import { IPoint } from './geometry';
import { IOrganisation } from './organisations';
import { IEditMutationVariables } from './mutations';
import { IWaterSourceSummary } from '../../components/inspectionView';

export interface IWaterSource {
    readonly waterSourceNodeId: string;
    readonly waterSourceId: string;
    readonly category: ILookupItem;
    readonly classification?: ILookupItem;
    readonly defects?: IDefect[];
    readonly repairs?: Repair[];
    readonly hazards?: IHazard[];
    readonly hazardSeverity?: ILookupItem;
    readonly inspectionFrequency?: number;
    readonly inspectionCount?: number;
    readonly inspections?: IInspection[];
    readonly isActive: boolean;
    readonly isDefective: IOperationalStatus;
    readonly isOperable: IOperationalStatus;
    readonly lastInspectionDate?: LocalDate;
    readonly location: ILocation;
    readonly nextInspectionDate?: LocalDate;
    readonly recommendedInspectionDate?: LocalDate;
    readonly notes?: INote[];
    readonly riskSeverity?: ILookupItem;
    readonly route?: IRoute;
    readonly routeSortOrder?: number;
    readonly scheme?: IScheme;
    readonly station?: ILookupItem;
    readonly status?: ILookupItem;
    readonly tags?: ILookupItem[];
    readonly organisation?: IOrganisation;
    readonly additionalInfo?: string;
    readonly show: boolean;
    readonly controlMeasures: ILookupItem[];
    readonly isSecondPersonRequired?: boolean;
    readonly roadSpeed?: ILookupItem;
    readonly hazardInformation?: string;
    readonly hazardFlag?: ILookupItem;
    readonly hazardInformation?: string;
    // hydrant
    readonly assetReference?: string;
    readonly flowRate?: number;
    readonly pressure?: number;
    readonly mainsSize?: IMeasurement;
    readonly surface?: ILookupItem;
    readonly hydrantLocation?: ILookupItem;
    // ews
    readonly accessDescription?: string;
    readonly capacity?: number;
    readonly isHardstanding?: boolean;
    readonly isSeasonal?: boolean;
    readonly lift?: number;
    readonly pumpCarryDistance?: number;
    readonly pumpCount?: number;
    readonly owner?: string;
    readonly pumpTypes?: ILookupItem[];
}

export type IOperationalStatus = WithOverride<{
    readonly value: boolean;
}>;

export type IMapWaterSource = Pick<IWaterSource,
    "waterSourceNodeId" |
    "waterSourceId" |
    "category" |
    "classification" |
    "isActive" |
    "isDefective" |
    "isOperable" |
    "status" |
    "riskSeverity" |
    "lastInspectionDate" |
    "recommendedInspectionDate" |
    "inspectionCount" |
    "show" |
    "tags" |
    "inspections"
> & {
    readonly location?: Pick<ILocation, "coordinates">;
};

export type EmergencyWaterSupply = Omit<IWaterSource,
    "assetReference" |
    "flowRate" |
    "hydrantLocation" |
    "mainsSize" |
    "pressure" |
    "surface"
>;

export type Hydrant = Omit<IWaterSource,
    "accessDescription" |
    "capacity" |
    "isHardstanding" |
    "isSeasonal" |
    "lift" |
    "pumpCarryDistance" |
    "pumpCount" |
    "owner" |
    "pumpTypes"
>;

export type Riser = UnknownWaterSource;

export type UnknownWaterSource = Omit<IWaterSource,
    // hydrant
    "assetReference" |
    "flowRate" |
    "hydrantLocation" |
    "mainsSize" |
    "pressure" |
    "surface" |
    // ews
    "accessDescription" |
    "capacity" |
    "isHardstanding" |
    "isSeasonal" |
    "lift" |
    "pumpCarryDistance" |
    "pumpCount" |
    "owner" |
    "pumpTypes"
>;

export interface IWaterSourceAction {
    readonly type: '@@INIT'
    | 'ADD_WATER_SOURCE'
    | 'ADD_WATER_SOURCE_HAZARDS'
    | 'ADD_WATER_SOURCE_TAGS'
    | 'EDIT_WATER_SOURCE'
    | 'REMOVE_WATER_SOURCE_HAZARDS'
    | 'REMOVE_WATER_SOURCE_TAGS'
    | 'SET_WATER_SOURCES'
    | 'SET_EDIT_WATER_SOURCE'
    | 'SET_EDIT_WATER_SOURCE_SUCCESS'
    | 'SET_SELECTED_WATER_SOURCE_LAST_INSPECTION_DATE'
    | 'SET_SELECTED_WATER_SOURCE'
    | 'SET_WATER_SOURCE_REQUEST_ID'
    | 'UPDATE_WATER_SOURCE_HAZARDS'
    | 'SET_IS_EDITING_WATER_SOURCE_ABORTED'
    | "UPDATE_WATER_SOURCE"
    | "UPDATE_WATER_SOURCE_OPERABILITY";
    readonly waterSources?: IMapWaterSource[];
    readonly waterSourcesPageInfo?: IPageInfo;
    readonly waterSourcesTotalCount?: number;
    readonly newWaterSource?: IWaterSource;
    readonly editedWaterSource?: IMapWaterSource;
    readonly selectedWaterSource?: IWaterSource;
    readonly createWaterSourceSuccess?: boolean;
    readonly editWaterSourceSuccess?: boolean;
    readonly waterSourceNotes?: INote[];
    readonly waterSourceTags?: ITagItem[];
    readonly waterSourceHazards?: IHazard[];
    readonly lastInspectionDate?: LocalDate;
    readonly editWaterSource?: boolean;
    readonly requestId?: string;
    readonly tags?: ITagItem[]
    readonly isEditingWaterSourceAborted?: boolean;
    readonly updatedWaterSource?: IWaterSourceSummary;
    readonly updatedOperability?: {
        readonly waterSourceNodeId: string;
        readonly isOperable: IOperationalStatus;
        readonly isDefective: IOperationalStatus;
    }
}

export interface IWaterSourceState {
    readonly waterSources?: IMapWaterSource[];
    readonly waterSourcesPageInfo?: IPageInfo;
    readonly waterSourcesTotalCount?: number;
    readonly selectedWaterSource?: IWaterSource;
    readonly editWaterSourceSuccess?: boolean;
    readonly editingSelectedWaterSource: boolean;
    readonly requestId?: string;
    readonly isEditingWaterSourceAborted?: boolean;
}

export enum WaterSourceCategory {
    HYDRANT = 1,
    EWS = 2,
    RISER = 3,
    UNKNOWN = 0
}

export interface ICreateWaterSourceInput {
    readonly externalId?: string;
    readonly locationCoordinates: IPoint;
    readonly classificationId: number;
    readonly statusId: number;
    readonly stationId: number;
    readonly locationAddressId?: number;
}

export interface ICreateWaterSourceVariables {
    readonly input: {
        readonly clientMutationId: string;
        readonly data: ICreateWaterSourceInput;
    };
}

export interface IWaterSourceInput {
    readonly externalId?: string | null;
    readonly classificationId?: number | null;
    readonly isOperable?: boolean | null;
    readonly isDefective?: boolean | null;
    readonly statusId?: number | null;
    readonly locationAddressId?: number | null;
    readonly locationCoordinates?: IPoint | null;
    readonly locationDescription?: string | null;
    readonly riskSeverityId?: number | null;
    readonly hazardSeverityId?: number | null;
    readonly nextInspectionDate?: LocalDate | null;
    readonly inspectionFrequency?: number | null;
    readonly stationId?: number | null;
    readonly organisationId?: number | null;
    readonly additionalInfo?: string | null;
    readonly controlMeasures?: number[];
    readonly isSecondPersonRequired?: boolean | null;
    readonly roadSpeedId?: number | null;
    readonly hazardInformation?: string | null;
    readonly hazardFlagId?: number | null;
}

export interface IHydrantInput extends IWaterSourceInput {
    readonly assetReference?: string | null;
    readonly plateDistance?: number | null;
    readonly plateLocation?: number | null;
    readonly mainsSize?: number | null;
    readonly mainsSizeUnitId?: number | null;
    readonly flowRate?: number | null;
    readonly pressure?: number | null;
    readonly surfaceId?: number | null;
    readonly schemeId?: string | null;
    readonly hydrantLocationId?: number | null;
}

export interface IEmergencyWaterSupplyInput extends IWaterSourceInput {
    readonly accessDescription?: string | null;
    readonly capacity?: number | null;
    readonly isHardstanding?: boolean | null;
    readonly isSeasonal?: boolean | null;
    readonly lift?: number | null;
    readonly owner?: string | null;
    readonly pumpCarryDistance?: number | null;
    readonly pumpCount?: number | null;
    readonly pumpTypes?: number[];
}

export interface IEditHydrantInput {
    readonly input: IEditMutationVariables<IHydrantInput>;
}

export interface IEditEmergencyWaterSupplyInput {
    readonly input: IEditMutationVariables<IEmergencyWaterSupplyInput>;
}

export interface IEditRiserOrUnknownInput {
    readonly input: IEditMutationVariables<IWaterSourceInput>;
}

export interface IUpdateWaterSourceActivationInput {
    readonly waterSource: IEditMutationVariables<string>;
}

export interface IWaterSourceQueryFilter {
    readonly id?: number;
    readonly externalId?: string;
    readonly categoryId?: number;
    readonly classificationId?: number;
    readonly statusId?: number;
    readonly isActive?: boolean;
    readonly isDefective?: boolean;
    readonly isOperable?: boolean;
    readonly openDefectCount?: number;
    readonly inoperableDefectCount?: number;
    readonly locationCoordinates?: IPoint;
    readonly locationDescription?: string;
    readonly riskSeverityId?: number;
    readonly hazardSeverityId?: number;
    readonly lastInspectionDate?: Date;
    readonly nextInspectionDate?: Date;
    readonly inspectionFrequency?: number;
    readonly stationId?: number;
    readonly routeId?: number;
    readonly routeSortOrder?: number;
    readonly organisationId?: number;
    readonly tags?: number[];
    readonly controlMeasures?: number[];
}
