export default `query defectControl_Query($id: ID!) {
    node(id:$id) {
        id
        ... on IWaterSource {
            waterSourceId
            defects {
                defectNodeId: id
                defectId
                type {
                    displayText
                    value
                }
                cancelReason {
                    displayText
                    value
                }
                closeReason {
                    displayText
                    value
                }
                dateReported
                reportedBy
                isOpen
                isResolved
                lastEvent {
                    timestamp
                    action
                    actor
                }
                parentInspection {
                    id
                }
                waterSource {
                    waterSourceNodeId: id
                }
                events {
                    timestamp
                    action
                    actor
                }
                notes {
                    noteNodeId: id
                    noteId
                    timestamp
                    author
                    content
                }
            }
        }
    }
}`;