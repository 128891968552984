import React from "react";

import { Checkbox, createStyles, FormControlLabel, makeStyles } from "@material-ui/core";

import { Lookup } from "../../../../../../lib/lookups";
import { AppDialogMultiSelect } from "../../../../../shared/appDialog";
import { ILookupItem } from "../../../../../../store/types";
import { EditFields, EditKeys } from "../../../waterSourceDetails/components/editor";
import TextInput from "../../../../../forms/textInput";
import Select from "../../../../../forms/select";
import SelectItem from "../../../../../forms/selectItem";
import { defaultHazardSeverity } from "../../../../../waterSource/waterSourceControl/waterSourceControl.utils";
import MutliSelectOptions from "../../../MultiSelectOptions";

interface EditorProps {
    readonly allHazardSeverity: Lookup;
    readonly hazardSeverity?: ILookupItem;
    readonly allHazards: Lookup;
    readonly hazardsValues?: number[];
    readonly isSecondPersonRequired?: boolean;
    readonly allControlMeasures: Lookup;
    readonly controlMeasures: ILookupItem[];
    readonly allRoadSpeed: Lookup;
    readonly roadSpeed?: ILookupItem;
    readonly allHazardFlags: Lookup;
    readonly hazardFlag?: ILookupItem;
    readonly native?: boolean;
    readonly hazardInformation?: string;
    readonly onChange?: (key: EditKeys, value: EditFields[EditKeys]) => void;
}

enum SelectField {
    HAZARD_SEVERITY = 'hazardSeverity',
    ROAD_SPEED = "roadSpeed",
    HAZARD_FLAG = "hazardFlag"
}

const useStyles = makeStyles(theme => createStyles({
    checkBoxLabel: {
        marginLeft: "0px"
    },
    checkbox: {
        color: theme.palette.primary.light
    }
}));

const Editor = (props: EditorProps): JSX.Element => {
    const {
        allHazardSeverity,
        hazardSeverity,
        allHazards,
        hazardsValues,
        isSecondPersonRequired,
        allControlMeasures,
        controlMeasures,
        allRoadSpeed,
        roadSpeed,
        allHazardFlags,
        hazardFlag,
        hazardInformation,
        native,
        onChange
    } = props;
    const styles = useStyles();

    const handleHazardsChange = (values: number[]): void => onChange?.("hazardsValues", values);

    const handleSecondTechnicianRequiredChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange?.("isSecondPersonRequired", event.target.checked);
    }

    const handleControlMeasuresChange = (values: number[]): void => {
        const selectedControlMeasures: ILookupItem[] = [];

        values.forEach(value => {
            const item = allControlMeasures.getItem(value);

            if (item) {
                selectedControlMeasures.push(item);
            }
        });
        onChange?.("controlMeasures", selectedControlMeasures);
    };

    const handleHazardInformationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { value } = event.target;

        onChange?.("hazardInformation", value || undefined);
    }

    const handleSelectChange = (field: SelectField) => {
        return (event: React.ChangeEvent<{ readonly value: unknown; }>): void => {
            const getItem = (lookup: Lookup): ILookupItem | undefined => {
                const { value } = event.target;
                if (typeof value === "string" && value) {
                    return lookup.getItem(Number(value));
                }
                if (typeof value === "number") {
                    return lookup.getItem(value);
                }
                return undefined;
            }
            switch (field) {
                case SelectField.HAZARD_SEVERITY:
                    onChange?.(SelectField.HAZARD_SEVERITY, getItem(allHazardSeverity));
                    return;
                case SelectField.ROAD_SPEED:
                    onChange?.(SelectField.ROAD_SPEED, getItem(allRoadSpeed));
                    return;
                case SelectField.HAZARD_FLAG:
                    onChange?.(SelectField.HAZARD_FLAG, getItem(allHazardFlags));
                    return;
            }
        };
    };

    return (
        <React.Fragment>
            <Select
                value={hazardSeverity?.value}
                id="hazard-severity"
                label="Hazard Severity"
                native={native}
                onChange={handleSelectChange(SelectField.HAZARD_SEVERITY)}
            >
                {allHazardSeverity.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
            <AppDialogMultiSelect
                value={hazardsValues}
                id="hazards"
                label="Hazards"
                native={native}
                isWhiteLabel
                onChange={handleHazardsChange}
            >
                <MutliSelectOptions items={allHazards} isMobile={native} />
            </AppDialogMultiSelect>
            <AppDialogMultiSelect
                value={controlMeasures?.map(controlMeasure => controlMeasure.value)}
                id="hazards-controlMeaures"
                label="Control Measures"
                native={native}
                isWhiteLabel
                onChange={handleControlMeasuresChange}
            >
                <MutliSelectOptions items={allControlMeasures} isMobile={native} />
            </AppDialogMultiSelect>
            <FormControlLabel
                label="Second Technician Required"
                labelPlacement="start"
                className={styles.checkBoxLabel}
                control={
                    <Checkbox
                        checked={isSecondPersonRequired}
                        onChange={handleSecondTechnicianRequiredChange}
                        className={styles.checkbox}
                        color="secondary"
                    />
                }
            />
            <Select
                value={roadSpeed?.value}
                id="hazards-road-speed"
                label="Road Speed"
                placeholder="Please select..."
                native={native}
                onChange={handleSelectChange(SelectField.ROAD_SPEED)}
            >
                {allRoadSpeed.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
            <TextInput
                value={hazardInformation ?? undefined}
                id="hazards-information"
                label="Hazard Information"
                multiline
                onChange={handleHazardInformationChange}
            />
            <Select
                value={hazardFlag?.value}
                id="hazards-flag"
                label="Hazard Flag"
                placeholder="Please select..."
                native={native}
                onChange={handleSelectChange(SelectField.HAZARD_FLAG)}
            >
                {allHazardFlags.map(item => {
                    return <SelectItem key={item.value} value={item.value}>{item.displayText}</SelectItem>;
                })}
            </Select>
        </React.Fragment>
    );
};

export type { EditorProps };
export default Editor;