import React, { useEffect } from 'react';

import { IGlobalNotice } from '../../../store/types';
import Typography from '../typography';

import styles from "./globalNotice.module.scss";

interface IGlobalNoticeProps {
    readonly globalNotice: IGlobalNotice;
}

const GlobalNotice = ({ globalNotice }: IGlobalNoticeProps): JSX.Element => {
    const { isShow, message, handleKeyPress } = globalNotice;

    if (!isShow || !message) {
        return <React.Fragment />
    }

    const handleEvent = (event: KeyboardEvent): void => {
        if (event.key === "Escape") {
            handleKeyPress?.();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleEvent);

        return (): void => {
            document.removeEventListener("keydown", handleEvent);
        }
    }, [handleEvent])

    return (
        <div className={styles.globalNotice}>
            <Typography>{message}</Typography>
        </div>
    )
};

export default GlobalNotice;