import React, { Component } from "react";

import Box from "@material-ui/core/Box";

import { NodeID, NodeType, encodeNodeId, parseNodeId } from "../../../lib/nodeIdentifier";
import { guid } from "../../../lib/Utils";
import {
    IPoint,
    ILookupItem,
    IEditHydrantInput,
    IEditEmergencyWaterSupplyInput,
    IEditRiserOrUnknownInput,
    IUpdateWaterSourceActivationInput,
    ILookups,
    IWaterSource,
    IGlobalToast,
    IHazard,
    IMapWaterSource,
    AddressNode
} from "../../../store/types";
import Divider from "../../Divider";
import { OptionValue, SelectOption } from "../../labelledField";
import { DialogVariant } from "../../utils/globalDialog";
import { HazardItem } from "./types";
import WaterSourceActions from "./components/WaterSourceActions";
import WaterSourceActive from "./components/WaterSourceActive";
import WaterSourceAdditionalInfo from "./components/WaterSourceAdditionalInfo";
import WaterSourceAssetReference from "./components/WaterSourceAssetReference";
import WaterSourceCategory from "./components/WaterSourceCategory";
import WaterSourceClassification from "./components/WaterSourceClassification";
import WaterSourceHazardList from "./components/WaterSourceHazardList";
import WaterSourceControlMeasures from "./components/WaterSourceControlMeasures";
import WaterSourceSecondTechnicianRequired from "./components/WaterSourceSecondTechnicianRequired";
import WaterSourceRoadSpeed from "./components/WaterSourceRoadSpeed";
import WaterSourceHazardInformation from "./components/WaterSourceHazardInformation";
import WaterSourceHazardFlag from "./components/WaterSourceHazardFlag";
import WaterSourceHazardSeverity from "./components/WaterSourceHazardSeverity";
import WaterSourceHydrantLocation from "./components/WaterSourceHydrantLocation";
import WaterSourceLastInspectionDate from "./components/WaterSourceLastInspectionDate";
import WaterSourceLocationCoordinates from "./components/WaterSourceLocationCoordinates";
import WaterSourceLocationDescription from "./components/WaterSourceLocationDescription";
import WaterSourceNextInspectionDate from "./components/WaterSourceNextInspectionDate";
import WaterSourceOperationalStatus from "./components/WaterSourceOperationalStatus";
import WaterSourceOrganisation from "./components/WaterSourceOrganisation";
import WaterSourceRiskSeverity from "./components/WaterSourceRiskSeverity";
import WaterSourceRouteLink from "./components/WaterSourceRouteLink";
import WaterSourceSchemeLink from "./components/WaterSourceSchemeLink";
import WaterSourceStation from "./components/WaterSourceStation";
import WaterSourceStatus from "./components/WaterSourceStatus";
import WaterSourceSurface from "./components/WaterSourceSurface";
import WaterSourceTags from "./components/WaterSourceTags";
import {
    getClassificationId,
    getLocationCoordinates,
    getIsDefective,
    getIsOperable,
    resetEditData,
    getStatus,
    getHydrantLocation,
    getSurface,
    getPressure,
    getFlowRate,
    getMainsSizeUnit,
    getMainsSize,
    getAssetReference,
    getOrganisation,
    getStation,
    getInspectionFrequency,
    getRiskSeverity,
    getLocationDescription,
    getLocationAddressId,
    operationalStatuses,
    getNextInspectionDate,
    IWaterSourceEditData,
    getAdditionalInfo,
    getAccessDescription,
    getIsHardstanding,
    getLift,
    getOwner,
    getControlMeasureValues,
    getRoadSpeed,
    getHazardInformation,
    getHazardFlag,
    getHazardTypes,
    prepareHazardsToSave,
    getCapacity,
    getHazardSeverity,
} from "./waterSourceControl.utils";
import { RoleName, RoleNames } from "../../../auth/roles";

import styles from "./waterSourceControl.module.scss";
import WaterSourceMainsSize from "./components/WaterSourceMainsSize";
import Optional from "../../shared/Optional";
import EwsOwner from "./components/EwsOwner";
import EwsHardstanding from "./components/EwsHardstanding";
import EwsAccessDescription from "./components/EwsAccessDescription";
import EwsPumpTypes from "./components/EwsPumpTypes";
import EwsLift from "./components/EwsList";
import EwsCapacity from './components/EwsCapacity';
import NotesTab from "../../notes/NotesTab";
import { IPrepareHazardsToSaveData } from "./types";
import { IWaterSourceSummary } from "../../inspectionView";
import WaterSourceInspectionFrequency from "./components/WaterSourceInspectionFrequency";
import WaterSourceAddress from "./components/WaterSourceAddress";
import { toAddressNode } from "../../address";
import { isAddressEqual } from "../../address/types";

interface IWaterSourceControlProps {
    readonly editing: boolean;
    readonly editWaterSourceSuccess: boolean;
    readonly lookups: ILookups;
    readonly movingWaterSource?: boolean;
    readonly movingWaterSourceLocation?: [number, number];
    readonly selectedWaterSource: IWaterSource;
    readonly fileStoreToken: string;
    readonly waterSources: IMapWaterSource[];
    readonly isEditingWaterSourceAborted?: boolean;
    readonly editEmergencyWaterSupply: (variables: IEditEmergencyWaterSupplyInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editHydrant: (variables: IEditHydrantInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editRiser: (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly editUnknownWaterSource: (variables: IEditRiserOrUnknownInput, triggerMapReRender: boolean, tags?: ILookupItem[]) => void;
    readonly onRefresh?: (waterSource?: IWaterSourceSummary) => void;
    readonly onSaveHazards?: (added: HazardItem[], removed: IHazard[]) => void;
    readonly onSavePumpTypes?: (pumpTypes: OptionValue[]) => void;
    readonly onShowRoute?: (id: NodeID) => void;
    readonly onShowScheme?: (id: NodeID) => void;
    readonly startUpdateWaterSourceActivation: (variables: IUpdateWaterSourceActivationInput) => void;
    readonly setDialogOpen: (dialog: DialogVariant) => void;
    readonly setEditWaterSource: (editing: boolean) => void;
    readonly setEditWaterSourceSuccess: () => void;
    readonly setGlobalToast: (toastProps?: IGlobalToast) => void;
    readonly setMovingWaterSource: (movingWaterSource: boolean) => void;
    readonly setRenderPoints: (renderPoints: boolean) => void;
    readonly editMapWaterSource: (mapWaterSource: IMapWaterSource) => void;
    readonly changeWaterSourceCoordinates?: (coordinates: [number, number]) => void;
    readonly setIsEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean) => void;
    readonly checkAccess: (role: RoleName) => boolean;
    readonly navigateToWaterSource: () => void;
}

interface IWaterSourceControlState {
    readonly shouldReRenderMap: boolean;
    readonly shouldValidate: boolean;
    readonly locationContainerHover: boolean;
    readonly initialLocation: IPoint;
    readonly initialHazards: SelectOption[];
    readonly data: IWaterSourceEditData;
}

type PropTypes<T> = T[keyof T];
type WaterSourceDataKeys = keyof IWaterSourceEditData;
type WaterSourceDataTypes = PropTypes<IWaterSourceEditData>;

class WaterSourceControl extends Component<IWaterSourceControlProps, IWaterSourceControlState> {
    constructor(props: IWaterSourceControlProps) {
        super(props);

        this.state = {
            shouldReRenderMap: false,
            shouldValidate: false,
            locationContainerHover: false,
            initialLocation: props.selectedWaterSource.location.coordinates,
            initialHazards: getHazardTypes(props.selectedWaterSource.hazards),
            data: {
                ...resetEditData(props.selectedWaterSource)
            }
        };
    }

    public componentDidUpdate(prevProps: IWaterSourceControlProps): void {
        if (prevProps.selectedWaterSource.waterSourceNodeId !== this.props.selectedWaterSource.waterSourceNodeId) {
            this.props.setEditWaterSource(false);
            this.updateState("isActive", this.props.selectedWaterSource.isActive);
            this.resetData();
        }

        const isEqual = (a: IPoint, b: IPoint): boolean => {
            return a?.x === b?.x && a?.y === b?.y;
        };

        if (this.props.editWaterSourceSuccess && isEqual(prevProps.selectedWaterSource.location.coordinates, this.props.selectedWaterSource.location.coordinates)) {
            this.props.setEditWaterSource(false);
            this.props.setEditWaterSourceSuccess();
        }

        if (!isEqual(prevProps.selectedWaterSource.location.coordinates, this.props.selectedWaterSource.location.coordinates)) {
            this.setState(state => ({
                data: {
                    ...state.data,
                    locationCoordinates: this.props.selectedWaterSource.location.coordinates
                }
            }));
        }

        if (!this.props.movingWaterSource && this.props.movingWaterSourceLocation && !this.props.editing) {
            this.props.setMovingWaterSource(false);
            this.saveWaterSource();
            this.props.setRenderPoints(true);
        }

        if (!this.props.movingWaterSource && this.props.movingWaterSourceLocation && this.props.editing) {
            this.props.changeWaterSourceCoordinates?.(this.props.movingWaterSourceLocation);
            this.changeSelectedMapWaterSourceCoordinates(this.props.movingWaterSourceLocation);
            this.props.setMovingWaterSource(false);
            this.props.setRenderPoints(true);
            this.props.setGlobalToast({
                message: 'The water source has been update',
                showToast: true,
                timeout: 3000
            });
        }

        if (this.props.isEditingWaterSourceAborted) {
            this.handleEditCancelClick();
            this.props.setIsEditingWaterSourceAborted(false);
        }

        if (!this.props.editing && !isEqual(this.state.initialLocation, this.props.selectedWaterSource.location.coordinates)) {
            this.setState({ initialLocation: this.props.selectedWaterSource.location.coordinates });
        }

        if (prevProps.selectedWaterSource.hazards !== this.props.selectedWaterSource.hazards) {
            this.setState({ initialHazards: getHazardTypes(this.props.selectedWaterSource.hazards) });
        }

        if (!this.props.editing && !isAddressEqual(prevProps.selectedWaterSource.location.address, this.props.selectedWaterSource.location.address)) {
            const { address } = this.props.selectedWaterSource.location ?? {};
            this.setState(current => ({
                ...current,
                data: {
                    ...current.data,
                    address: address ? toAddressNode(address) : undefined
                }
            }));
        }
    }

    public componentWillUnmount(): void {
        this.props.setGlobalToast()
        this.props.setEditWaterSource(false)
    }

    public render(): JSX.Element {
        const { editing, selectedWaterSource, lookups, checkAccess } = this.props;
        const { data } = this.state;
        const hasAccess = checkAccess(RoleNames.WS_ALL);

        const handleCancel = (): void => {
            this.handleEditCancelClick();
        };
        const handleEdit = (): void => {
            this.props.setEditWaterSource(true);
        };
        const handleSave = (): void => {
            this.handleSaveClick();
        };

        const handleValue = (key: WaterSourceDataKeys, refreshMap?: boolean): ((value: WaterSourceDataTypes) => void) => {
            return (value): void => this.updateState(key, value, refreshMap);
        };
        const handleClear = (key: WaterSourceDataKeys, refreshMap?: boolean): (() => void) => {
            const value = key === "hazards" ? [] : undefined;
            return (): void => handleValue(key, refreshMap)(value);
        };
        const handleAddressClear = (): void => {
            this.setState(current => ({
                data: {
                    ...current.data,
                    address: undefined
                }
            }));
        };
        const handleAddressSelect = (address: AddressNode): void => {
            this.props.onRefresh?.();
            this.setState(current => ({
                data: {
                    ...current.data,
                    address: toAddressNode(address)
                }
            }));
        };
        const handleAddressUpdate = (address: AddressNode): void => {
            if (this.state.data.address?.addressNodeId === address.addressNodeId) {
                handleAddressSelect(address);            
            }
        };

        return (
            <div className={styles.root}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                    <WaterSourceCategory waterSource={selectedWaterSource} />
                    <WaterSourceOperationalStatus
                        waterSource={selectedWaterSource}
                        editing={editing}
                        options={operationalStatuses}
                        value={data.operationalStatusOverride}
                        onSelect={handleValue("operationalStatusOverride", true)}
                    />
                    <WaterSourceActions editing={editing} waterSourceNodeId={parseNodeId(selectedWaterSource.waterSourceNodeId)} waterSourceId={selectedWaterSource.waterSourceId} onCancel={handleCancel} onEdit={handleEdit} onSave={handleSave} onRefresh={this.props.onRefresh} />
                </Box>
                <Divider />
                <div>
                    <WaterSourceActive
                        editing={editing}
                        checked={data.isActive}
                        onChange={handleValue("isActive", true)}
                    />
                    <WaterSourceTags
                        waterSource={selectedWaterSource}
                        editing={editing}
                        onChange={(): void => this.setState({ shouldReRenderMap: true })}
                    />
                    <WaterSourceOrganisation
                        editing={editing}
                        options={lookups.organisations}
                        groups={lookups.organisationTypes}
                        value={data.organisation}
                        onSelect={handleValue("organisation")}
                    />
                    <WaterSourceClassification
                        show={selectedWaterSource.category.value > 0}
                        editing={editing}
                        options={lookups.waterSourceClasses}
                        discriminator={selectedWaterSource.category}
                        value={data.classification}
                        onSelect={handleValue("classification")}
                    />
                    <WaterSourceStatus
                        editing={editing}
                        options={lookups.waterSourceStatuses}
                        discriminator={selectedWaterSource.category}
                        value={data.status}
                        onSelect={handleValue("status", true)}
                    />
                    <WaterSourceRiskSeverity
                        editing={editing}
                        options={lookups.riskSeverity}
                        value={data.riskSeverity}
                        onSelect={handleValue("riskSeverity")}
                    />
                </div>
                <Divider />
                <div>
                    <WaterSourceLocationDescription
                        editing={editing}
                        value={data.locationDescription}
                        onChange={handleValue("locationDescription")}
                    />
                    <WaterSourceAddress
                        editing={editing}
                        address={data.address}
                        onClear={handleAddressClear}
                        onSelect={handleAddressSelect}
                        onUpdate={handleAddressUpdate}
                    />
                    <WaterSourceHydrantLocation
                        editing={editing}
                        show={selectedWaterSource.category.value === 1}
                        options={lookups.hydrantLocations}
                        value={data.hydrantLocation}
                        onSelect={handleValue("hydrantLocation")}
                    />
                    <WaterSourceSurface
                        editing={editing}
                        options={lookups.surfaces}
                        value={data.surface}
                        onClear={handleClear("surface")}
                        onSelect={handleValue("surface")}
                    />
                    <WaterSourceLocationCoordinates
                        editing={editing}
                        value={data.locationCoordinates ?? selectedWaterSource.location.coordinates}
                        hideSearch={false}
                        onChange={handleValue("locationCoordinates", true)}
                        onSearchClick={(): void => {
                            this.props.setMovingWaterSource(true);
                            this.setState({ shouldReRenderMap: true })
                        }}
                        navigateToWaterSource={this.props.navigateToWaterSource}
                    />
                </div>
                <Divider />
                <div>
                    <WaterSourceStation
                        editing={editing}
                        options={lookups.stations}
                        value={data.station}
                        onSelect={handleValue("station")}
                    />
                    <WaterSourceRouteLink route={selectedWaterSource.route} onClick={this.props.onShowRoute} />
                    <WaterSourceSchemeLink scheme={selectedWaterSource.scheme} onClick={this.props.onShowScheme} />
                </div>
                <Divider />
                <div>
                    <WaterSourceLastInspectionDate value={selectedWaterSource.lastInspectionDate} />
                    <WaterSourceInspectionFrequency editing={editing} value={data.inspectionFrequency} onChange={handleValue("inspectionFrequency")} />
                    <WaterSourceNextInspectionDate editing={editing} value={data.nextInspectionDate} recommended={selectedWaterSource.recommendedInspectionDate} onChange={handleValue("nextInspectionDate")} />
                </div>
                <Divider />
                <div>
                    <WaterSourceHazardSeverity
                        editing={editing}
                        options={lookups.riskSeverity}
                        value={data.hazardSeverity}
                        onClear={handleClear("hazardSeverity")}
                        onSelect={handleValue("hazardSeverity")}
                    />
                    <WaterSourceHazardList
                        options={lookups.hazardTypes}
                        selectedOptions={data.hazards}
                        editing={editing && hasAccess}
                        onChange={handleValue("hazards")}
                    />
                    <WaterSourceControlMeasures
                        options={lookups.controlMeasures}
                        selectedOptions={data.controlMeasures}
                        editing={editing}
                        onChange={handleValue("controlMeasures")}
                    />
                    <WaterSourceSecondTechnicianRequired
                        editing={editing}
                        checked={data.isSecondPersonRequired}
                        onChange={handleValue("isSecondPersonRequired")}
                    />
                    <WaterSourceRoadSpeed
                        editing={editing}
                        options={lookups.roadSpeed}
                        value={data.roadSpeed}
                        onSelect={handleValue("roadSpeed")}
                    />
                    <WaterSourceHazardInformation editing={editing} value={data.hazardInformation} onChange={handleValue("hazardInformation")} />
                    <WaterSourceHazardFlag
                        editing={editing}
                        options={lookups.hazardFlags}
                        value={data.hazardFlag}
                        onSelect={handleValue("hazardFlag")}
                    />
                </div>
                <Optional hidden={selectedWaterSource.category.value !== 1} >
                    {/* Hydrant Only */}
                    <Divider />
                    <WaterSourceAssetReference
                        editing={editing}
                        value={data.assetReference}
                        onChange={handleValue("assetReference")}
                    />
                    <WaterSourceMainsSize
                        editing={editing}
                        value={data.mainsSize}
                        units={lookups.mainsSizeUnits}
                        selectedUnit={data.mainsSizeUnit}
                        onChange={handleValue("mainsSize")}
                        onClear={handleClear("mainsSizeUnit")}
                        onSelectUnit={handleValue("mainsSizeUnit")}
                    />
                </Optional>
                <Optional hidden={selectedWaterSource.category.value !== 2}>
                    {/* Emergency Water Supply only */}
                    <Divider />
                    <EwsOwner
                        editing={editing}
                        value={data.owner}
                        onChange={handleValue("owner")}
                    />
                    <EwsCapacity
                        editing={editing}
                        value={data.capacity}
                        onChange={handleValue("capacity")}
                    />
                    <EwsHardstanding
                        editing={editing}
                        checked={data.isHardstanding}
                        onChange={handleValue("isHardstanding")}
                        onClear={handleClear("isHardstanding")}
                    />
                    <EwsLift
                        editing={editing}
                        value={data.lift}
                        onChange={handleValue("lift")}
                    />
                    <EwsAccessDescription
                        editing={editing}
                        value={data.accessDescription}
                        onChange={handleValue("accessDescription")}
                    />
                    <EwsPumpTypes
                        options={lookups.pumpTypes}
                        selected={selectedWaterSource.pumpTypes ?? []}
                        onChange={this.props.onSavePumpTypes}
                    />
                </Optional>
                <Divider />
                <div>
                    <WaterSourceAdditionalInfo editing={editing} value={data.additionalInfo} onChange={handleValue("additionalInfo")} />
                </div>
                <Divider />
                <NotesTab objectType="WATER_SOURCE" objectNodeId={selectedWaterSource.waterSourceNodeId} notes={selectedWaterSource.notes ?? []} />
            </div>
        );
    }

    /* --------------------------------------------------------------------- */

    private handleEditCancelClick(): void {
        this.props.setMovingWaterSource(false);
        this.props.setEditWaterSource(false);
        this.resetData();
        this.props.changeWaterSourceCoordinates?.([this.state.initialLocation?.x, this.state.initialLocation?.y]);
        this.changeSelectedMapWaterSourceCoordinates(this.state.initialLocation);
        this.props.setRenderPoints(true);
        this.setState({ shouldValidate: false });
        this.props.onRefresh?.(this.props.selectedWaterSource);
    }

    private handleSaveClick(): void {
        this.saveWaterSource();
    }

    private resetData(): void {
        this.setState((prevState: IWaterSourceControlState, props: IWaterSourceControlProps) => {
            const state: IWaterSourceControlState = {
                ...prevState,
                data: {
                    ...resetEditData(props.selectedWaterSource)
                }
            };
            return state;
        });
    }

    private saveWaterSource(): void {
        this.setState({ shouldValidate: true });

        this.props.setGlobalToast()

        const { selectedWaterSource } = this.props;
        const { data, shouldReRenderMap } = this.state;

        const categoryId = selectedWaterSource.category.value;
        const variables: IEditHydrantInput | IEditEmergencyWaterSupplyInput | IEditRiserOrUnknownInput = {
            input: {
                clientMutationId: guid(),
                nodeId: selectedWaterSource.waterSourceNodeId,
                data: {
                    // all water sources
                    classificationId: getClassificationId(data, categoryId),
                    isDefective: getIsDefective(data),
                    isOperable: getIsOperable(data),
                    statusId: getStatus(data),
                    locationAddressId: getLocationAddressId(data),
                    locationCoordinates: getLocationCoordinates(data, selectedWaterSource.location.coordinates, this.props.movingWaterSourceLocation),
                    locationDescription: getLocationDescription(data),
                    riskSeverityId: getRiskSeverity(data),
                    inspectionFrequency: getInspectionFrequency(data),
                    nextInspectionDate: getNextInspectionDate(data),
                    stationId: getStation(data),
                    organisationId: getOrganisation(data),
                    additionalInfo: getAdditionalInfo(data),
                    controlMeasures: getControlMeasureValues(data),
                    isSecondPersonRequired: data.isSecondPersonRequired,
                    roadSpeedId: getRoadSpeed(data),
                    hazardInformation: getHazardInformation(data),
                    hazardFlagId: getHazardFlag(data),
                    hazardSeverityId: getHazardSeverity(data),
                    // hydrants
                    assetReference: getAssetReference(data, categoryId),
                    plateDistance: undefined,
                    plateLocation: undefined,
                    mainsSize: getMainsSize(data, categoryId),
                    mainsSizeUnitId: getMainsSizeUnit(data, categoryId),
                    flowRate: getFlowRate(data, categoryId),
                    pressure: getPressure(data, categoryId),
                    surfaceId: getSurface(data, categoryId),
                    hydrantLocationId: getHydrantLocation(data, categoryId),
                    // ews
                    accessDescription: getAccessDescription(data, categoryId),
                    isHardstanding: getIsHardstanding(data, categoryId),
                    lift: getLift(data, categoryId),
                    owner: getOwner(data, categoryId),
                    capacity: getCapacity(data, categoryId)
                }
            }
        };

        switch (selectedWaterSource.category.value) {
            case 1:
                this.props.editHydrant(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            case 2:
                this.props.editEmergencyWaterSupply(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            case 3:
                this.props.editRiser(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
            default:
                this.props.editUnknownWaterSource(variables, shouldReRenderMap, selectedWaterSource.tags);
                break;
        }

        if (this.props.selectedWaterSource.isActive !== this.state.data.isActive) {
            this.updateActiveStatus();
        }

        this.props.setEditWaterSource(false);

        this.saveHazards();

        this.setState({ shouldReRenderMap: false });
    }

    private updateActiveStatus(): void {
        const variables: IUpdateWaterSourceActivationInput = {
            waterSource: {
                clientMutationId: guid(),
                nodeId: this.props.selectedWaterSource.waterSourceNodeId,
                data: this.state.data.isActive ? "ACTIVE" : "INACTIVE"
            }
        };
        this.props.startUpdateWaterSourceActivation(variables);
    }

    private updateState(key: WaterSourceDataKeys, value: WaterSourceDataTypes, shouldReRenderMap?: boolean): void {
        this.setState(current => ({
            ...current,
            shouldReRenderMap: shouldReRenderMap ?? false,
            initialLocation: {
                ...current.initialLocation
            },
            data: {
                ...current.data,
                [key]: value
            }
        }));
    }

    private changeSelectedMapWaterSourceCoordinates = (coordinates: IPoint | [number, number]): void => {
        const finalCoordinates: IPoint = Array.isArray(coordinates) ? { x: coordinates[0], y: coordinates[1] } : coordinates;
        const selectedMapWaterSource = this.props.waterSources.find(waterSource => waterSource.waterSourceNodeId === this.props.selectedWaterSource.waterSourceNodeId);
        if (selectedMapWaterSource) {
            const updatedSelectedMapWaterSource: IMapWaterSource = {
                ...selectedMapWaterSource,
                location: {
                    ...selectedMapWaterSource.location,
                    coordinates: finalCoordinates
                }
            }
            this.props.editMapWaterSource(updatedSelectedMapWaterSource);
        }
    }

    private saveHazards = (): void => {
        const hazardSeverityValue = this.state.data.hazardSeverity?.value;
        const hazardsValues = this.state.data.hazards.map(hazard => hazard.value);
        const prepareHazardsToSaveData: IPrepareHazardsToSaveData = {
            hazardsValues,
            hazardSeverityValue,
            allHazardsTypes: this.props.lookups.hazardTypes,
            initialHazardsValues: this.state.initialHazards.map(initialHazard => initialHazard.value),
            allHazards: this.props.selectedWaterSource.hazards
        };

        const { addedHazards, deletedHazards } = prepareHazardsToSave(prepareHazardsToSaveData);

        if (!hazardsValues.length) {
            this.setState(state => ({
                data: {
                    ...state.data,
                    hazardSeverity: undefined
                }
            }));
        }

        this.props.onSaveHazards?.(addedHazards, deletedHazards);
    }
}

export type { IWaterSourceControlProps };
export default WaterSourceControl;