import React from "react";
import classnames from "classnames";
import { LocalDate } from "@js-joda/core";

import DateInput from "../../../utils/DateInput";

import { EditElementProps } from "../../types";
import { containerClass, inputClass, labelClass } from "../../labelledField.utils";

import ElementContainer from "../elementContainer";
import LabelElement from "../labelElement";
import ReadOnlyElement from "../readOnlyElement";

type DateElementProps = EditElementProps<LocalDate>;

const DateElement = (props: DateElementProps): JSX.Element => {
    if (props.editing) {
        const handleChange = (value: LocalDate | undefined): void => {
            props.onChange(value);
        };
        const labelPosition = props.labelPosition ?? "TOP";
        return (
            <ElementContainer noMargin={props.noMargin} classes={containerClass(props)}>
                {labelPosition === "TOP" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
                <DateInput id={props.id} value={props.value} onChange={handleChange} classes={classnames(inputClass(props))} />
                {labelPosition === "BOTTOM" && <LabelElement ownerId={props.id} label={props.label} classes={labelClass(props)} />}
            </ElementContainer>
        );
    }
    return <ReadOnlyElement id={props.id} label={props.label} noMargin={props.noMargin} classes={props.classes} value={props.value} />;
};

export type { DateElementProps };
export default DateElement;