import React from "react";
import { useDispatch } from "react-redux";
import Feature from "ol/Feature";

import { RoleNames } from "../../auth/roles";
import useSelector from "../../lib/hooks/useSelector";
import { NodeID } from "../../lib/nodeIdentifier";
import { setGlobalToast, setDialogOpen } from "../../store/actions/app";
import { setRenderPoints, setMovingWaterSource, startSetMapClickAction, setCreateFromMapLocation, setMapCenterCoords, setMapFallbackWarning } from "../../store/actions/map";
import { createWaterPipe, removeWaterPipe, createThoroughfare, removeThoroughfare } from "../../store/actions/schemes";
import { getWaterSources, getWaterSourcesByFilter, setIsEditingWaterSourceAborted } from "../../store/actions/waterSources";
import { ICoordinate, IPoint, IScheme } from "../../store/types";
import { useRoute, useScheme, useWaterSource, useFilters, useOnWaterSourceChanged } from "../appWindowContext";
import useRefreshMap from "../appWindowContext/hooks/useRefreshMap";
import useFocusMap from "./useFocusMap";
import MapPage, { IMapPageProps } from "./MapPage";

const ConnectedMapPage = (): JSX.Element => {
    const dispatch = useDispatch();

    const [filters, , clearFilters] = useFilters();
    const { instance: route } = useRoute();
    const { instance: scheme, refresh: refreshScheme, setBoundary: setSchemeBoundary } = useScheme();
    const { instance: waterSource, change: showWaterSource, clear: clearWaterSource } = useWaterSource();

    useRefreshMap(scheme ?? route);
    useFocusMap(waterSource ?? scheme ?? route);

    useOnWaterSourceChanged((): void => {
        dispatch(setRenderPoints(true));
    });

    const props: IMapPageProps = {
        accessControl: useSelector(state => ({
            manageWaterSources: state.app.user?.account?.access.some(rule => rule.permissionname === RoleNames.WS_ALL)
        })),
        filters,
        mapClickAction: useSelector(state => state.map.mapClickAction),
        mapSettings: useSelector(state => state.map.mapSettings ?? {
            projection: { code: "", definition: "" },
            tileServer: { apiKey: "", type: "", url: "", params: undefined },
            view: {
                centre: { x: 0, y: 0 },
                extent: { max: { x: 0, y: 0 }, min: { x: 0, y: 0 } }
            }
        }),
        mapFallbackWarning: useSelector(state => state.map.fallbackWarning ?? false),
        movingWaterSource: useSelector(state => state.map.movingWaterSource),
        renderAnimateTo: useSelector(state => state.map.renderAnimateTo),
        renderPoints: useSelector(state => state.map.renderPoints),
        selectedRoute: route,
        selectedScheme: scheme,
        selectedWaterSource: waterSource,
        selectWaterSourceNodeId: waterSource?.waterSourceNodeId,
        shouldReloadWaterSources: useSelector(state => state.app.shouldReloadWaterSources),
        statuses: useSelector(state => state.app.lookups?.waterSourceStatuses ?? []),
        waterSources: useSelector(state => state.waterSources.waterSources ?? []),
        editingSelectedWaterSource: useSelector(state => state.waterSources.editingSelectedWaterSource),
        //
        createThoroughfare: async (schemeNodeId: string, path: IPoint[]): Promise<void> => {
            const action = createThoroughfare(schemeNodeId, path);
            await action(dispatch);
            await refreshScheme();
        },
        createWaterPipe: async (schemeNodeId: string, path: IPoint[]): Promise<void> => {
            const action = createWaterPipe(schemeNodeId, path);
            await action(dispatch);
            await refreshScheme();
        },
        getWaterSources: async (variables): Promise<void> => {
            const action = getWaterSources(variables);
            await action(dispatch);
        },
        getWaterSourcesByFilter: async (variables, pageIndex, forMap): Promise<void> => {
            const action = getWaterSourcesByFilter(variables, pageIndex, forMap);
            await action(dispatch);
        },
        onChangeSchemeBoundary: (schemeNodeId: string, boundary: ICoordinate[]): void => {
            if (schemeNodeId === scheme?.schemeNodeId) {
                setSchemeBoundary(boundary);
            }
        },
        onClearWaterSource: (): void => {
            clearWaterSource();
        },
        onCreateWaterSource: (): void => {
            dispatch(setDialogOpen("createWaterSource"));
        },
        onMapCreateWaterSourceClick: (): void => {
            dispatch(startSetMapClickAction("CREATE_WATER_SOURCE", true));
        },
        onMapClearFiltersClick: (): void => {
            clearFilters();
        },
        onMapMeasureDistanceClick: (): void => {
            dispatch(startSetMapClickAction("MEASURE_DISTANCE", true));
        },
        onMapOpenStreetViewClick: (): void => {
            dispatch(startSetMapClickAction("STREET_VIEW", true));
        },
        onMapSelectClick: (): void => {
            dispatch(startSetMapClickAction(undefined, true));
        },
        onMeasuredDistance: (length): void => {
            dispatch(setGlobalToast({
                message: `Final line length - ${length}`,
                showToast: true,
                type: "INFO",
                timeout: 3000
            }));
        },
        onSelectWaterSource: (id: NodeID): void => {
            showWaterSource(id, {
                focusMap: undefined,
                tab: undefined,
                defect: undefined,
                inspection: undefined,
                repair: undefined
            });
        },
        removeThoroughfare: async (scheme: IScheme, feature: Feature): Promise<void> => {
            const action = removeThoroughfare(scheme, feature);
            await action(dispatch);
            await refreshScheme();
        },
        removeWaterPipe: async (scheme: IScheme, feature: Feature): Promise<void> => {
            const action = removeWaterPipe(scheme, feature);
            await action(dispatch);
            await refreshScheme();
        },
        setCreateFromMapLocation: (createFromMapLocation: [number, number]): void => {
            dispatch(setCreateFromMapLocation(createFromMapLocation));
        },
        setMapCenterCoords: (mapCenterCoords): void => {
            dispatch(setMapCenterCoords(mapCenterCoords))
        },
        setMovingWaterSource: (movingWaterSource?: boolean, movingWaterSourceLocation?: [number, number]): void => {
            dispatch(setMovingWaterSource(movingWaterSource, movingWaterSourceLocation));
        },
        setRenderPoints: (renderPoints): void => {
            dispatch(setRenderPoints(renderPoints));
        },
        setIsEditingWaterSourceAborted: (isEditingWaterSourceAborted: boolean): void => {
            dispatch(setIsEditingWaterSourceAborted(isEditingWaterSourceAborted));
        },
        onTileServerFallback: (showWarning: boolean): void => {
            dispatch(setMapFallbackWarning(showWarning));
        }
    };

    return <MapPage {...props} />;
};

export default ConnectedMapPage;