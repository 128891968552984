import React, { useState, useRef, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from "@material-ui/core/Divider";
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import { AccessControl } from "../../../../../auth/components";
import { RoleNames } from "../../../../../auth/roles";

const useStyles = makeStyles(theme => createStyles({
    root: {

    },
    button: {
        color: theme.palette.primary.contrastText
    },
    menu: {
        minWidth: theme.spacing(25),
        zIndex: 10
    }
}));

interface SchemeMenuProps {
    readonly hasBoundary?: boolean;
    readonly onAssignWaterSource?: () => void;
    readonly onCreateWaterSource?: () => void;
    readonly onEditBoundary?: (action: "DRAW_POLYGON" | "EDIT_POLYGON") => void;
    readonly onEditThoroughfares?: (action: "DRAW_THOROUGHFARE" | "DELETE_THOROUGHFARE") => void;
    readonly onEditWaterPipes?: (action: "DRAW_WATER_PIPE" | "DELETE_WATER_PIPE") => void;
    readonly onCreateInspectionsClick: () => void;
}

const SchemeMenu = (props: SchemeMenuProps): JSX.Element => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const anchor = useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen(previous => !previous);
    };

    const handleClose = (): void => {
        if (anchor.current?.contains(event?.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchor.current?.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleEditBoundary = (): void => {
        handleClose();
        props.onEditBoundary?.(props.hasBoundary ? "EDIT_POLYGON" : "DRAW_POLYGON");
    };

    const handleCreateWaterSource = (): void => {
        handleClose();
        props.onCreateWaterSource?.();
    };
    const handleAssignWaterSource = (): void => {
        handleClose();
        props.onAssignWaterSource?.();
    };

    const handleAddWaterPipes = (): void => {
        handleClose();
        props.onEditWaterPipes?.("DRAW_WATER_PIPE");
    };
    const handleRemoveWaterPipes = (): void => {
        handleClose();
        props.onEditWaterPipes?.("DELETE_WATER_PIPE");
    };

    const handleAddThoroughfares = (): void => {
        handleClose();
        props.onEditThoroughfares?.("DRAW_THOROUGHFARE");
    };
    const handleRemoveThoroughfares = (): void => {
        handleClose();
        props.onEditThoroughfares?.("DELETE_THOROUGHFARE");
    };

    const handleCreateInspectionsClick = (): void => {
        handleClose();
        props.onCreateInspectionsClick();
    };

    return (
        <AccessControl any={[RoleNames.SCHEMES_ALL, RoleNames.WS_ALL]}>
            <div className={styles.root}>
                <IconButton ref={anchor} className={styles.button} aria-controls={open ? "scheme-menu" : undefined} aria-haspopup="true" onClick={handleToggle}>
                    <MenuIcon />
                </IconButton>
                <Popper className={styles.menu} open={open} anchorEl={anchor.current} role={undefined} transition disablePortal placement="bottom-start">
                    {({ TransitionProps }): JSX.Element => (
                        <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="scheme-menu" onKeyDown={handleListKeyDown}>
                                        <AccessControl role={RoleNames.SCHEMES_ALL}>
                                            <MenuItem onClick={handleEditBoundary}>{`${props.hasBoundary ? "Edit" : "Add"} Boundary`}</MenuItem>
                                        </AccessControl>
                                        <AccessControl role={RoleNames.SCHEMES_ALL}>
                                            <MenuItem onClick={handleCreateInspectionsClick}>Create Inspections</MenuItem>
                                        </AccessControl>
                                        <AccessControl all={[RoleNames.SCHEMES_ALL, RoleNames.WS_ALL]}>
                                            <Divider />
                                        </AccessControl>
                                        <AccessControl role={RoleNames.WS_ALL}>
                                            <MenuItem onClick={handleCreateWaterSource}>Create Water Source</MenuItem>
                                        </AccessControl>
                                        <AccessControl role={RoleNames.SCHEMES_ALL}>
                                            <MenuItem onClick={handleAssignWaterSource}>Assign Water Source</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={handleAddWaterPipes}>Add Water Pipes</MenuItem>
                                            <MenuItem onClick={handleRemoveWaterPipes}>Remove Water Pipes</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={handleAddThoroughfares}>Add Thoroughfares</MenuItem>
                                            <MenuItem onClick={handleRemoveThoroughfares}>Remove Thoroughfares</MenuItem>
                                        </AccessControl>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </AccessControl>
    );
};

export type { SchemeMenuProps };
export default SchemeMenu;