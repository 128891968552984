import { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { stringify } from "querystring";

import { NodeID, encodeNodeId } from "../../../lib/nodeIdentifier";
import { startSetMapClickAction } from "../../../store/actions/map";
import useAppWindowParams, { AppWindowParams, QueryString, QueryParams, WaterSourceTab } from "./useAppWindowParams";

type EncodeResult<T> = T extends NodeID ? string : undefined;

type WaterSourceTabLabel = "WATER_SOURCE" | "INSPECTIONS" | "DEFECTS" | "REPAIRS";

const encode = <T extends NodeID | undefined>(id: T): EncodeResult<T> => {
    return id
        ? encodeNodeId(id as NodeID) as EncodeResult<T>
        : undefined as EncodeResult<T>;
};
const encodeBoolean = (value: boolean | undefined): string | undefined => {
    return value === undefined
        ? undefined
        : value ? "true" : "false";
};
const encodeFilters = (filters: Record<string, unknown> | undefined): string | undefined => {
    const encodeFilters = (): string => {
        const s = JSON.stringify(filters);
        const a = btoa(s);
        const r = encodeURIComponent(a);
        return r;
    };
    return filters
        ? encodeFilters()
        : undefined;
};
const encodeTab = (tab: WaterSourceTab | undefined): WaterSourceTabLabel | undefined => {
    switch (tab) {
        case WaterSourceTab.WATER_SOURCE:
            return "WATER_SOURCE";
        case WaterSourceTab.INSPECTIONS:
            return "INSPECTIONS";
        case WaterSourceTab.DEFECTS:
            return "DEFECTS";
        case WaterSourceTab.REPAIRS:
            return "REPAIRS";
        default:
            return undefined;
    }
};

interface AppWindowFeatures {
    readonly clearRoute: () => void;
    readonly clearScheme: () => void;
    readonly clearWaterSource: () => void;
    readonly clearDefect: () => void;
    readonly clearInspection: () => void;
    readonly clearRepair: () => void;
    readonly clearFocusMap: () => void;
    readonly clearFilters: () => void;
}

const toQueryString = (params: AppWindowParams): string => {
    const buildQuery = (params: AppWindowParams): QueryString<AppWindowParams> => {
        const reducer = (query: QueryString<QueryParams>, current: [string, string | undefined]): QueryString<QueryParams> => {
            const [key, value] = current;
            return value === undefined
                ? query
                : { ...query, [key]: value };
        };

        const q: QueryString<QueryParams> = {
            filters: encodeFilters(params.filters),
            route: encode(params.route),
            scheme: encode(params.scheme),
            ws: encode(params.waterSource),
            tab: encodeTab(params.tab),
            defect: encode(params.defect),
            inspection: encode(params.inspection),
            repair: encode(params.repair),
            focusMap: encodeBoolean(params.focusMap)
        };
        const query = Object.entries(q).reduce(reducer, {});
        return query;
    };
    const query = buildQuery(params);
    const qs = stringify(query);
    return qs;
};

const useAppWindowFeatures = (): AppWindowFeatures => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const params = useAppWindowParams();

    const clearQueryString = useCallback((rest: AppWindowParams): void => {
        const qs = toQueryString(rest);
        history.push({
            ...location,
            search: qs ? `?${qs}` : undefined
        });
    }, [history, location]);

    const clearMapAction = (): void => {
        dispatch(startSetMapClickAction());
    };

    const features = useMemo(() => ({
        clearRoute: (): void => {
            const { route, ...rest } = params;
            clearQueryString(rest);
            clearMapAction();
            return;
        },
        clearScheme: (): void => {
            const { scheme, ...rest } = params;
            clearQueryString(rest);
            clearMapAction();
            return;
        },
        clearWaterSource: (): void => {
            const { waterSource, tab, defect, inspection, repair, focusMap, ...rest } = params;
            clearQueryString(rest);
            clearMapAction();
            return;
        },
        clearDefect: (): void => {
            const { defect, ...rest } = params;
            clearQueryString(rest);
            return;
        },
        clearInspection: (): void => {
            const { inspection, ...rest } = params;
            clearQueryString(rest);
            return;
        },
        clearRepair: (): void => {
            const { repair, ...rest } = params;
            clearQueryString(rest);
            return;
        },
        clearFocusMap: (): void => {
            const { focusMap, ...rest } = params;
            clearQueryString(rest);
            return;
        },
        clearFilters: (): void => {
            const { filters, ...rest } = params;
            clearQueryString(rest);
            return;
        }
    }), [params, location]);
    return features;
};

export type { AppWindowFeatures };
export { toQueryString };
export default useAppWindowFeatures;