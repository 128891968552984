import React from "react";
import { useDispatch } from 'react-redux';

import useSelector from "../../../lib/hooks/useSelector";
import { parseNodeId } from "../../../lib/nodeIdentifier";
import { guid } from '../../../lib/Utils';
import { setGlobalToast, setDialogOpen } from '../../../store/actions/app';
import { setError } from '../../../store/actions/errors';
import { beginUpdateRoute, beginUpdateRouteWaterSources } from '../../../store/actions/routes';
import { startSetMapClickAction } from '../../../store/actions/map';
import { IEditRouteInput } from '../../../store/types';
import { useRoute, useWaterSource } from "../../appWindowContext";
import { compareWaterSources, printRoute } from './RoutePanel.utils';
import RoutesPanelBase, { IRoutesPanelProps } from './RoutesPanel.view';

const RoutesPanel = ({ route, onClose, onUpdateRoute }: Pick<IRoutesPanelProps, "route" | "onClose" | "onUpdateRoute">): JSX.Element => {
    const dispatch = useDispatch();
    const { change: selectWaterSource } = useWaterSource();
    const { setShowOnMap } = useRoute();

    const stations = useSelector(state => state.app.lookups?.stations ?? []);

    const props: IRoutesPanelProps = {
        stations,
        route,
        mapSettings: useSelector(state => state.map.mapSettings ?? {
            projection: { code: "", definition: "" },
            tileServer: { apiKey: "", type: "", url: "", params: undefined },
            view: {
                centre: { x: 0, y: 0 },
                extent: { max: { x: 0, y: 0 }, min: { x: 0, y: 0 } }
            }
        }),
        onClose,
        onCreateInspectionsClick: (waterSourceIds): void => {
            dispatch(setDialogOpen("createInspection", { waterSourcesToAssign: waterSourceIds }));
        },
        onCreateWaterSourceClick: (): void => {
            dispatch(startSetMapClickAction("CREATE_WATER_SOURCE", true));
        },
        onError: (error, errorObject): void => {
            dispatch(setError(error, errorObject));
        },
        onInfo: (message): void => {
            dispatch(setGlobalToast({
                type: "INFO",
                message: message,
                showToast: true
            }));
        },
        onPrint: (route, imageUrl): void => {
            printRoute(route, imageUrl);
        },
        onUpdateRoute: async (routeNodeId, name, station): Promise<void> => {
            const variables: IEditRouteInput = {
                input: {
                    clientMutationId: guid(),
                    nodeId: routeNodeId,
                    data: {
                        name: name,
                        stationId: station.value
                    }
                }
            };
            const action = beginUpdateRoute(variables);
            await action(dispatch);
            onUpdateRoute?.(routeNodeId, name, station);
        },
        onUpdateRouteWaterSources: async (routeNodeId, waterSources): Promise<void> => {
            const waterSourceIds = waterSources
                .sort(compareWaterSources)
                .map(ws => ws.waterSourceNodeId);
            const action = beginUpdateRouteWaterSources(routeNodeId, waterSourceIds, { refreshMap: true });
            await action(dispatch);
        },
        onWaterSourceSelect: (waterSourceNodeId): void => {
            selectWaterSource(parseNodeId(waterSourceNodeId));
        },
        setShowOnMap
    };

    return <RoutesPanelBase {...props} />;
}

export default RoutesPanel;
