export default `
query inspectionList_Query($id: ID!) {
    node(id:$id) {
        id
        ... on IWaterSource {
            waterSourceId
            inspections {
                inspectionNodeId: id
                inspectionId
                type {
                    displayText
                    value
                }
                isOpen
                events {
                    action
                    actor
                    timestamp
                }
                lastEvent {
                    timestamp
                    action
                    actor
                }
            }
        }
    }
}`;