import { connect } from "react-redux";

import { IReduxState } from "../../../store/types";
import MapViewer, { MapProps } from "./map.view";
import { Projection, TileServer, View } from "./types";

type ConnectedProps = Pick<MapProps, "settings">;

const defaultProjection: Projection = {
    code: "EPSG:27700",
    definition: "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs"
};

const defaultTileServer: TileServer = {
    apiKey: "",
    type: "",
    url: ""
};

const defaultView: View = {
    centre: { x: 0, y: 0 },
    extent: {
        max: { x: 612435.55, y: 1234954.16 },
        min: { x: -90619.29, y: 10097.13 }
    }
};

const mapProps = (state: IReduxState): ConnectedProps => {
    const { projection, tileServer, view } = state.map.mapSettings ?? {};
    return ({
        settings: {
            projection: projection ?? defaultProjection,
            tileServer: tileServer ?? defaultTileServer,
            view: view ?? defaultView
        }
    });
};

export default connect(mapProps)(MapViewer);